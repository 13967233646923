import { stacks } from '@dropbox/api-v2-client';
import { IconButton } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { AddCircleLine, OpenLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { DashNewLinkType } from '@mirage/analytics/events/enums/dash_new_link_type';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { Link } from '@mirage/link/Link';
import { openURL } from '@mirage/service-platform-actions';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { KeyboardEvent, SyntheticEvent } from 'react';
import { AddToSectionMenu } from './AddToSectionMenu';
import styles from './LinkSuggestions.module.css';
import { TabSuggestion } from './types';

interface LinkSuggestionProps {
  className: string | undefined;
  setRef: (ref: HTMLElement | null) => void;
  url: string;
  addLink: (
    dashNewLinkType: DashNewLinkType,
    link: string | TabSuggestion,
    title?: string,
    sectionId?: string,
  ) => void;
  tabSuggestion: TabSuggestion | undefined;
  onOpenLink: ((tabSuggestion: TabSuggestion) => void) | undefined;
  onKeyDown: (e: KeyboardEvent<HTMLElement>) => void;
  sections?: stacks.Section[];
  showSectionMenu?: boolean;
}

export const LinkSuggestion: React.FC<LinkSuggestionProps> = ({
  className,
  setRef,
  url,
  addLink,
  tabSuggestion,
  onOpenLink,
  onKeyDown,
  sections,
  showSectionMenu,
}) => {
  const { title, faviconUrl, icon, connectorDisplayName } = tabSuggestion ?? {};
  const isAugRev = useStackPageAugustRevisionEnabled();

  return (
    <Link
      ref={setRef}
      href={url}
      role="menuitem"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!isAugRev) {
          addLink('search_result', tabSuggestion ?? url, title);

          // Show href in bottom-left of browser, but don't open link.
          return false;
        }

        // Open link in new tab.
        openURL(url);
        tabSuggestion && onOpenLink?.(tabSuggestion);
      }}
      onKeyDown={(e) => {
        onKeyDownCommitFn(() => {
          // Prevent double creation of link.
          e.preventDefault();
          if (!isAugRev) {
            addLink('search_result', url, title);
            return;
          }
          // Open link in new tab.
          openURL(url);
          tabSuggestion && onOpenLink?.(tabSuggestion);
        })(e);

        onKeyDown?.(e);
      }}
      className={classNames(styles.suggestionsContainer, className)}
    >
      <div className={styles.suggestionsLeftContent}>
        {icon ? (
          icon
        ) : (
          <img
            alt={i18n.t('icon_for_item')}
            src={faviconUrl}
            className={styles.linkFavicon}
          />
        )}
        <DigTooltip
          title={
            (title || url) +
            (connectorDisplayName ? ` · ${connectorDisplayName}` : '')
          }
        >
          <Text className={styles.suggestionText}>
            {title || url}
            {connectorDisplayName && (
              <Text color="faint"> · {connectorDisplayName}</Text>
            )}
          </Text>
        </DigTooltip>
      </div>
      {isAugRev ? (
        <div>
          <DigTooltip title={i18n.t('add_to_stack')}>
            <IconButton
              variant="borderless"
              aria-label={i18n.t('add_to_stack')}
              data-testid="addSuggestedLinkBtn"
              onClick={(e: SyntheticEvent<HTMLElement>) => {
                // Keep the menu open.
                e.stopPropagation();
                e.preventDefault();

                addLink('search_result', tabSuggestion ?? url, title);

                // Show href in bottom-left of browser, but don't open link.
                return false;
              }}
              onKeyDown={(e: KeyboardEvent<HTMLElement>) => {
                onKeyDownCommitFn(() => {
                  // Prevent double creation of link.
                  e.preventDefault();
                  e.stopPropagation();

                  addLink('search_result', tabSuggestion ?? url, title);
                })(e);

                onKeyDown?.(e);
              }}
            >
              <UIIcon
                src={AddCircleLine}
                className={styles.openSuggestionIcon}
                size="large"
              />
            </IconButton>
          </DigTooltip>
          {showSectionMenu && !!sections?.length && (
            <AddToSectionMenu
              onSelectSection={(sectionId) => {
                addLink(
                  'search_result',
                  tabSuggestion ?? url,
                  title,
                  sectionId,
                );
              }}
              sections={sections}
            />
          )}
        </div>
      ) : (
        <div className={styles.suggestionsButton}>
          <DigTooltip title={i18n.t('open_in_new_tab')}>
            <IconButton
              variant="opacity"
              onClick={(e: SyntheticEvent<HTMLElement>) => {
                // Keep the menu open.
                e.stopPropagation();
                e.preventDefault();

                // Open link in new tab.
                openURL(url);
                tabSuggestion && onOpenLink?.(tabSuggestion);
              }}
            >
              <UIIcon
                src={OpenLine}
                className={styles.openSuggestionIcon}
                size="large"
              />
            </IconButton>
          </DigTooltip>
        </div>
      )}
    </Link>
  );
};
