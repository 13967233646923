/*
 * Note: This is non-end-user facing code so no need for i18n strings.
 */

import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import MagicFlagSettings from '@mirage/mosaics/MagicFlags/MagicFlagsSettings';
import {
  refreshAllFeatureValues,
  resetAllFeatureOverrides,
} from '@mirage/service-experimentation';
import { features } from '@mirage/service-experimentation/features';
import { useFeatureFlags } from '@mirage/service-experimentation/useInitFeatureFlags';
import { useFeatureRingSettings } from '@mirage/service-feature-ring-settings/hooks/useFeatureRingSettings';
import sortBy from 'lodash/sortBy';
import { useEffect, useMemo } from 'react';
import { FeatureList } from './FeatureList';
import { FeatureRingPicker } from './FeatureRingPicker';
import styles from './FeaturesSettings.module.css';

export default function FeaturesSettings() {
  const featureFlags = useFeatureFlags();
  const sortedFeatures = useMemo(() => {
    const f = Object.values(features);
    return sortBy(f, ({ featureName }) => featureName);
  }, []);

  const featuresWithOverride = useMemo(
    () =>
      sortedFeatures.filter(
        ({ featureName }) =>
          featureFlags[featureName].overrideValue !== undefined,
      ),
    [featureFlags, sortedFeatures],
  );

  const {
    featureRingSettings,
    getCurrentFeatureRing,
    clearOverride,
    setOveride,
    isOveridden,
  } = useFeatureRingSettings();

  useEffect(() => {
    getCurrentFeatureRing();
  }, [getCurrentFeatureRing]);

  return (
    <div className={styles.wrapper}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text size="medium" isBold>
          Feature Ring
        </Text>
        <Box>
          <Button
            variant="outline"
            onClick={async () => {
              await clearOverride();
              refreshAllFeatureValues();
            }}
            disabled={!isOveridden()}
          >
            Reset to default
          </Button>
        </Box>
      </Box>
      <hr className={styles.horizontalSeparator} />
      <FeatureRingPicker
        featureFlags={featureFlags}
        featureRingSettings={featureRingSettings}
        onSetOverride={async (value) => {
          await setOveride(value);
          await refreshAllFeatureValues();
        }}
      ></FeatureRingPicker>

      {featuresWithOverride.length > 0 && (
        <>
          <Box paddingBottom="16" paddingTop="32">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text size="medium" isBold>
                Active Feature Flag Overrides
              </Text>
              <Box>
                <Button
                  variant="outline"
                  onClick={async () => {
                    await resetAllFeatureOverrides();
                  }}
                >
                  Clear all overrides
                </Button>
              </Box>
            </Box>

            <hr className={styles.horizontalSeparator} />
          </Box>
          <FeatureList sortedFeatures={featuresWithOverride} />
        </>
      )}

      <Box display="block" paddingBottom="16" paddingTop="32">
        <Text size="medium" isBold>
          Feature Flags
        </Text>
        <hr className={styles.horizontalSeparator} />
      </Box>
      <FeatureList sortedFeatures={sortedFeatures} />
      <Box display="block" paddingBottom="16" paddingTop="32">
        <Text size="medium" isBold>
          Magic Flags
        </Text>
        <hr className={styles.horizontalSeparator} />
      </Box>
      <MagicFlagSettings />
    </div>
  );
}
