import { context_engine } from '@dropbox/api-v2-client/types/dropbox_types';
import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { callApiV2 } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { ComposeSession } from '@mirage/shared/compose/compose-session';
import { AccessType, ComposeVoice } from '@mirage/shared/compose/compose-voice';
import { KVStorage } from '@mirage/storage';

import type { LogoutServiceConsumerContract } from '@mirage/service-logout';

export type Service = ReturnType<typeof composeService>;

export interface ComposeSessionsStorage {
  sessions: {
    rows: ComposeSession[];
    version: 1;
  };
}

export interface ComposeVoicesStorage {
  voices: ComposeVoice[];
}

export enum StorageKey {
  Voices = 'voices',
  Sessions = 'sessions',
}

export default function composeService(
  sessionsStore: KVStorage<ComposeSessionsStorage>,
  voicesStore: KVStorage<ComposeVoicesStorage>,
  logoutService: LogoutServiceConsumerContract,
) {
  const logger = tagged('compose-service');

  async function loadComposeSessions(): Promise<ComposeSession[]> {
    const sessions = await sessionsStore.get(StorageKey.Sessions);
    if (sessions?.version === 1) {
      logger.log('loaded ComposeSessions', sessions.rows.length);
      return sessions.rows;
    } else {
      // Ignore old data if the version is not >=1
      return [];
    }
  }

  async function loadComposeApiSessions(): Promise<ComposeSession[]> {
    const args: context_engine.AssistListUserDataArg = {
      user_data_type: StorageKey.Sessions,
    };
    const response = await callApiV2(
      'contextEngineAssistApiListUserData',
      args,
    );
    const loadedSessions: ComposeSession[] = [];
    if (!response.items) {
      return loadedSessions;
    }
    Object.entries(response.items).forEach(([key, item]) => {
      if (!item.user_data) {
        return;
      }
      const sessionData = JSON.parse(item.user_data);
      const session: ComposeSession = {
        ...sessionData,
        dataId: key,
        rev: item.rev,
      };
      if (verifySessionData(session)) {
        loadedSessions.push(session);
      } else {
        logger.error('Invalid session data:', sessionData);
      }
    });
    logger.log('Loaded remote assistant sessions', loadedSessions.length);
    return loadedSessions;
  }

  function verifySessionData(sessionData: ComposeSession): boolean {
    const isValid =
      typeof sessionData.id === 'string' &&
      typeof sessionData.dataId === 'string' &&
      Array.isArray(sessionData.messagesHistory) &&
      Array.isArray(sessionData.sources) &&
      Array.isArray(sessionData.artifacts) &&
      (sessionData.lastUpdated === undefined ||
        typeof sessionData.lastUpdated === 'number');
    if (!isValid) {
      logger.error('Invalid session data:', sessionData);
    }
    return isValid;
  }

  async function saveComposeSessions(sessions: ComposeSession[]) {
    await sessionsStore.set(StorageKey.Sessions, {
      rows: sessions,
      version: 1,
    });
    logger.log('saved ComposeSessions', sessions.length);
  }

  async function saveComposeApiSession(session: ComposeSession) {
    const args: context_engine.AssistSaveUserDataArg = {
      data_item: {
        user_data_type: StorageKey.Sessions,
        user_data_key: session.id,
        user_data: JSON.stringify(session),
      },
      data_id: session.dataId,
    };
    const response = await callApiV2(
      'contextEngineAssistApiSaveUserData',
      args,
    );
    if (response.data_id) {
      session.dataId = response.data_id;
    }
    logger.log('saved ComposeSession', session.id);
    return session;
  }

  /*
   * Syncs local session with API sessions
   * Updates sessions with newer versions
   * Uploads legacy sessions
   * Marks deleted sessions for local deletion
   * Adds new sessions from API to local
   */
  async function syncComposeSessions(
    localSessions: ComposeSession[],
    apiSessions: ComposeSession[],
  ): Promise<{ updated: ComposeSession[]; deletedIds: string[] }> {
    const updatedSessions: ComposeSession[] = [];
    const deletedIds: string[] = [];
    const apiSessionsMap = new Map(apiSessions.map((s) => [s.id, s]));
    const localSessionsMap = new Map(localSessions.map((s) => [s.id, s]));

    // Go through local sessions and if any session is newer in the API, update it locally.
    for (const localSession of localSessions) {
      const apiSession = apiSessionsMap.get(localSession.id);
      if (apiSession) {
        if (
          apiSession.rev &&
          (!localSession.rev || apiSession.rev > localSession.rev)
        ) {
          updatedSessions.push(apiSession);
          logger.log(
            'Updated session with newer version from API:',
            localSession.id,
          );
        }
        // If a local session has no dataId, it was never uploaded to the API
        // so create a new API session, and update the local session with the dataId
      } else if (!localSession.dataId) {
        const savedSession = await saveComposeApiSession(localSession);
        updatedSessions.push(savedSession);
        logger.log('Saved local session to API:', localSession.id);
      } else {
        // If a session has a dataId but it didn't come back from the API, it was deleted
        // so mark it for local deletion
        // TODO (will): figure out a plan for safe deletions when sessions aren't coming back
        // deletedIds.push(localSession.id);
        // logger.log('Session deleted from API:', localSession.id);
      }
    }

    // Go through API sessions and add any new sessions that don't exist locally
    for (const apiSession of apiSessions) {
      if (!localSessionsMap.has(apiSession.id)) {
        updatedSessions.push(apiSession);
        logger.log('Added new session from API:', apiSession.id);
      }
    }

    return { updated: updatedSessions, deletedIds };
  }

  async function deleteComposeApiSession(session: ComposeSession) {
    if (!session.dataId) {
      return;
    }
    const args: context_engine.AssistDeleteUserDataArg = {
      data_id: session.dataId,
    };
    await callApiV2('contextEngineAssistApiDeleteUserData', args);
    logger.log('deleted ComposeSession', session.id);
    return;
  }

  async function loadComposeVoices(): Promise<ComposeVoice[]> {
    const voices = (await voicesStore.get(StorageKey.Voices)) || [];
    logger.log('loaded ComposeVoices', voices?.length);
    return voices || [];
  }

  async function loadComposeApiVoices(): Promise<ComposeVoice[]> {
    const args: context_engine.AssistListUserDataArg = {
      user_data_type: StorageKey.Voices,
    };
    const response = await callApiV2(
      'contextEngineAssistApiListUserData',
      args,
    );
    const loadedVoices: ComposeVoice[] = [];
    if (!response.items) {
      return loadedVoices;
    }
    Object.entries(response.items).forEach(([key, item]) => {
      if (!item.user_data) {
        return;
      }
      const voiceData = JSON.parse(item.user_data);
      const voice: ComposeVoice = {
        ...voiceData,
        dataId: key,
        rev: item.rev,
        accessType: voiceData.accessType || AccessType.INDIVIDUAL,
        is_owned: item.is_owned || false,
      };
      if (verifyVoiceData(voice)) {
        loadedVoices.push(voice);
      } else {
        logger.error('Invalid voice data:', voiceData);
      }
    });
    logger.log('Loaded remote voices', loadedVoices.length);
    return loadedVoices;
  }

  function verifyVoiceData(voiceData: ComposeVoice): boolean {
    const isValid =
      typeof voiceData.id === 'string' &&
      typeof voiceData.name === 'string' &&
      typeof voiceData.description === 'string' &&
      typeof voiceData.dataId === 'string' &&
      Array.isArray(voiceData.sources);
    if (!isValid) {
      logger.error('Invalid voice data:', voiceData);
    }
    return isValid;
  }

  async function saveComposeVoices(voices: ComposeVoice[]) {
    await voicesStore.set(StorageKey.Voices, voices);
    logger.log('saved ComposeVoices', voices.length);
  }

  async function saveComposeApiVoice(voice: ComposeVoice) {
    const args: context_engine.AssistSaveUserDataArg = {
      data_item: {
        user_data_type: StorageKey.Voices,
        user_data_key: voice.id,
        user_data: JSON.stringify(voice),
      },
      data_id: voice.dataId,
      access_type: {
        '.tag': voice.accessType || AccessType.INDIVIDUAL,
      },
    };
    const response = await callApiV2(
      'contextEngineAssistApiSaveUserData',
      args,
    );
    if (response.data_id) {
      voice.dataId = response.data_id;
    }
    logger.log('saved ComposeVoice', voice.id);
    return voice;
  }

  async function deleteComposeApiVoice(voice: ComposeVoice) {
    if (!voice.dataId) {
      return;
    }
    const args: context_engine.AssistDeleteUserDataArg = {
      data_id: voice.dataId,
    };
    await callApiV2('contextEngineAssistApiDeleteUserData', args);
    logger.log('deleted ComposeVoice', voice.id);
    return;
  }

  async function tearDown() {
    await sessionsStore.clear();
    await voicesStore.clear();
  }

  logoutService.registerLogoutCallback(ServiceId.COMPOSE, async () => {
    logger.debug('Handling logout in compose service');
    await tearDown();
    logger.debug('Done handling logout in compose service');
  });

  return services.provide(
    ServiceId.COMPOSE,
    {
      loadComposeSessions,
      loadComposeApiSessions,
      saveComposeSessions,
      saveComposeApiSession,
      deleteComposeApiSession,
      loadComposeVoices,
      loadComposeApiVoices,
      saveComposeVoices,
      saveComposeApiVoice,
      deleteComposeApiVoice,
      syncComposeSessions,
      tearDown,
      verifySessionData,
      verifyVoiceData,
    },
    [ServiceId.DBX_API],
  );
}
