import { DashSurface } from '@mirage/analytics/events/enums/dash_surface';
import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import {
  ExperimentSource,
  FeatureFlag,
  FeatureName,
  featuresList,
  FeatureValue,
} from '@mirage/service-experimentation/features';
import { tagged } from '@mirage/service-logging';
import { logPageLoadMilestone } from '@mirage/service-operational-metrics/page-load';
import Sentry from '@mirage/shared/sentry';
import { getCombineAsyncRequestsFunc } from '@mirage/shared/util/combine-async-requests';
import {
  ONE_DAY_IN_SECONDS,
  ONE_MINUTE_IN_MILLIS,
} from '@mirage/shared/util/constants';
import { register, unregister } from '@mirage/shared/util/jobs';
import { runWithRetries } from '@mirage/shared/util/retries';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import { LargeKvStore } from '@mirage/storage/large-kv-store/interface';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import { createExperimentationAttributes } from '../util';
import {
  cancelSyncFeatureMemCache,
  startSyncFeatureMemCache,
} from './memCache';

import type { users } from '@dropbox/api-v2-client';
import type { LogoutServiceConsumerContract } from '@mirage/service-logout';
import type { Observable } from 'rxjs';

const CACHE_KEY = 'FeatureFlags';
const EXPIRY_SECONDS = ONE_DAY_IN_SECONDS * 10;
const SYNC_FEATURE_FLAGS_JOB_NAME = 'feature-flags-sync';
const SYNC_INTERVAL_MS = ONE_MINUTE_IN_MILLIS * 15;

// For now, these have to match the growthbook attributes list: https://app.dropboxexperiment.com/attributes
export type ExperimentationAttributes = {
  device_id?: string;
  session_id?: string;
  id?: string; // Deprecated - "dbid:xxx" format
  team_id?: string; // Deprecated - "dbtid:xxx" format
  public_user_id?: string;
  public_team_id?: string;
  app_version?: string;
  os_name?: string;
  browser?: string;
  country?: string;
  locale?: string;
  employee?: boolean;
  platform?: DashSurface;
  dbx51_email_regex?: string;
  feature_rollout_ring?: string;
  custom_string?: string;
};

type GroupedFeatures = Record<ExperimentSource, FeatureFlag[]>;

export type ExperimentationServiceAdapter = {
  fetchFeatureValues: (features: FeatureFlag[]) => Promise<FeatureFlag[]>;
  setAttributes: (attributes: ExperimentationAttributes) => void;
  listenForFeatures?: () => Observable<FeatureFlag[]>;
  source: ExperimentSource;
};

export type ExperimentationService = Awaited<
  ReturnType<typeof experimentationService>
>;

const logger = tagged('service-experimentation');

export interface AuthServiceContract {
  getAccessToken(): Promise<string | undefined>;
  getCurrentAccount(refresh: boolean): Promise<users.FullAccount | undefined>;
  getInstallId(): Promise<string>;
  getSessionId(): Promise<string>;
}

export interface FeatureRingSettingsServiceContract {
  getCurrentFeatureRing(): Promise<string | undefined>;
  tearDown(): Promise<void>;
}

function getRetryOptions(message: string) {
  return {
    logError: (error: unknown, attemptNum: number, numAttempts: number) => {
      Sentry.captureMessage(
        message + ` - attempt ${attemptNum} of ${numAttempts}`,
        'warning',
        {
          originalException: error,
        },
      );
    },
    logFinalError: (error: unknown, numAttempts: number) => {
      Sentry.captureMessage(
        message + ` - made ${numAttempts} attempts total`,
        'error',
        {
          originalException: error,
        },
      );
    },
  };
}

export default async function experimentationService({
  adapters,
  storage,
  authService,
  ringService,
  logoutService,
}: {
  adapters: ExperimentationServiceAdapter[];
  storage: LargeKvStore;
  authService: AuthServiceContract;
  ringService: FeatureRingSettingsServiceContract;
  logoutService: LogoutServiceConsumerContract;
}) {
  const initialValue = await readCachedFeatureFlags();
  const currentValue$ = new rx.BehaviorSubject(initialValue);

  // XXX: this flag is slightly overloaded and covers two cases. the first is if
  // we have just started the service and want calls to fetch fresh values and
  // overlay current overrides on top of them. the second case, is if we clear
  // our storage (e.g. user log out) so that a new login will trigger a fetch
  let shouldMakeRequestToFetchFlags = true;

  //
  // external api
  //----------------------------------------------------------------------------
  function listenForFeatures(): Observable<FeatureFlag[]> {
    return currentValue$.asObservable();
  }

  function setExperimentationAttributes(attributes: ExperimentationAttributes) {
    logger.debug('setExperimentationAtributes()', attributes);

    for (const adapter of adapters) {
      adapter.setAttributes(attributes);
    }
  }

  async function resetAllFeatureOverrides(): Promise<void> {
    logger.debug('resetAllFeatureOverrides()');
    const cachedFlags = await getCachedFlags();
    if (!cachedFlags.length) return;
    const newFlags = cachedFlags.map(({ overrideValue: _, ...flag }) => flag);
    return setCachedFlagsAndNotify(newFlags);
  }

  async function getAllExperimentationAttributes(): Promise<ExperimentationAttributes> {
    const accessToken = await authService.getAccessToken();

    if (accessToken) {
      const [installId, sessionId, account] = await Promise.all([
        authService.getInstallId(),
        authService.getSessionId(),
        runWithRetries(
          () => authService.getCurrentAccount(/* refresh= */ false),
          getRetryOptions('service-experimentation getCurrentAccount'),
        ),
      ]);

      // run this after getCurrentAccount completes as I believe it's failing
      // on the first attempt if it completes before getCurrentAccount
      const featureRing = await runWithRetries(
        () => ringService.getCurrentFeatureRing(),
        getRetryOptions('service-experimentation getCurrentFeatureRing'),
      );

      return createExperimentationAttributes(
        installId,
        sessionId,
        account,
        featureRing,
      );
    } else {
      const [installId, sessionId] = await Promise.all([
        authService.getInstallId(),
        authService.getSessionId(),
      ]);

      return createExperimentationAttributes(installId, sessionId);
    }
  }

  const refreshAllFeatureValues = getCombineAsyncRequestsFunc(
    rawRefreshAllFeatureValues,
  );

  async function rawRefreshAllFeatureValues(): Promise<FeatureFlag[]> {
    const attributes = await getAllExperimentationAttributes();
    setExperimentationAttributes(attributes);

    logPageLoadMilestone('refreshAllFeatureValues start');
    logger.debug('refreshAllFeatureValues()');
    const adapterValues = await fetchAllFeatureValues();
    logger.debug('refreshAllFeatureValues() - fetch complete');
    // XXX: doing local cache lookup after fetching to avoid overwriting
    // anything that was modified while the requests were in flight
    logger.debug('refreshAllFeatureValues() - get cached flags');
    const cachedFlags = await getCachedFlags();
    logger.debug('refreshAllFeatureValues() - apply existing overrides');
    const flags = applyExistingOverrides(cachedFlags, adapterValues);
    logger.debug('refreshAllFeatureValues() - setting cached flags');
    await setCachedFlagsAndNotify(flags);
    shouldMakeRequestToFetchFlags = false;
    logger.debug('refreshAllFeatureValues() - complete');
    logPageLoadMilestone('refreshAllFeatureValues end');
    return flags;
  }

  async function setFeatureOverride(
    name: FeatureName,
    value: FeatureValue,
  ): Promise<void> {
    logger.debug('setFeatureOverride()', { name, value });
    const cachedFlags = await getCachedFlags();
    const newFlags = overrideFlagOrAdd(cachedFlags, name, value);
    await setCachedFlagsAndNotify(newFlags);
  }

  async function startSyncFeatureFlags() {
    register(
      SYNC_FEATURE_FLAGS_JOB_NAME,
      SYNC_INTERVAL_MS,
      true,
      refreshAllFeatureValues,
    );
  }

  async function cancelSyncFeatureFlags() {
    unregister(SYNC_FEATURE_FLAGS_JOB_NAME);
  }

  //
  // internal helpers
  //----------------------------------------------------------------------------
  async function fetchAllFeatureValues(): Promise<FeatureFlag[]> {
    logger.debug('fetchAllFeatureValues()');
    const groupedFeatures = groupBy(
      featuresList,
      ({ source }) => source,
    ) as GroupedFeatures;

    const adapterPromises = Object.entries(groupedFeatures).map(
      async ([source, features]) => {
        const adapter = nonNil(
          adapters.find((a) => a.source === source),
          `adapter for ${source}`,
        );
        logger.debug('fetchAllFeatureValues() - fetching', source);
        const values = await runWithRetries(
          () => adapter.fetchFeatureValues(features),
          getRetryOptions(
            `service-experimentation ${adapter.source} fetchFeatureValues`,
          ),
        );
        logger.debug('fetchAllFeatureValues() - completed', source);
        return values;
      },
    );

    const results = await Promise.all(adapterPromises);
    logger.debug('fetchAllFeatureValues() - complete');
    return results.flat();
  }

  async function setCachedFlagsAndNotify(flags: FeatureFlag[]): Promise<void> {
    logger.debug('setCachedFlagsAndNotify()');
    const cachedFlags = await getCachedFlags();

    // clear override value if it matches the server value.
    const normalizedFlags = flags.map(({ overrideValue, ...flag }) => {
      if (flag.value === undefined) return { overrideValue, ...flag };
      if (!isEqual(flag.value, overrideValue)) {
        return { overrideValue, ...flag };
      }
      return flag;
    });

    // only persist and externally notify if the flag values have changed
    if (isEqual(cachedFlags, normalizedFlags)) {
      logger.debug('setCachedFlagsAndNotify() - flags did not change');
      return;
    }

    logger.debug('setCachedFlagsAndNotify() - writing flags to cache');
    await writeCachedFeatureFlags(normalizedFlags);
    logger.debug('setCachedFlagsAndNotify() - notifying');
    currentValue$.next(normalizedFlags);
  }

  function overrideFlagOrAdd(
    flags: FeatureFlag[],
    name: FeatureName,
    value: FeatureValue,
  ): FeatureFlag[] {
    const idx = flags.findIndex(({ featureName }) => featureName === name);
    const target = flags[idx] ?? getEmptyFeatureFlag(name);
    const overridden = { ...target, overrideValue: value };

    if (!flags.length) return [overridden];
    if (idx === -1) return [...flags, overridden];
    return [...flags.slice(0, idx), overridden, ...flags.slice(idx + 1)];
  }

  function getEmptyFeatureFlag(name: FeatureName): FeatureFlag {
    return cloneDeep(
      nonNil(
        featuresList.find((f) => f.featureName === name),
        `feature "${name}"`,
      ),
    );
  }

  function applyExistingOverrides(
    cachedFlags: FeatureFlag[],
    newFlags: FeatureFlag[],
  ): FeatureFlag[] {
    if (!cachedFlags.length) return newFlags;

    const overrideValueByFeatureName: {
      [name: string]: Exclude<FeatureValue, undefined>;
    } = {};

    for (const flag of cachedFlags) {
      if (flag.overrideValue === undefined) continue;
      overrideValueByFeatureName[flag.featureName] = flag.overrideValue;
    }

    return newFlags.map((flag) => {
      const overrideValue = overrideValueByFeatureName[flag.featureName];
      if (overrideValue === undefined) return flag;
      return {
        ...flag,
        overrideValue,
      };
    });
  }

  const getAllCachedOrFetchFeatureValues = getCombineAsyncRequestsFunc(
    rawGetAllCachedOrFetchFeatureValues,
  );

  async function rawGetAllCachedOrFetchFeatureValues(): Promise<FeatureFlag[]> {
    logPageLoadMilestone('getAllCachedOrFetchFeatureValues start');
    if (shouldMakeRequestToFetchFlags) return refreshAllFeatureValues();

    const cachedFlags = await getCachedFlags();
    logPageLoadMilestone('getAllCachedOrFetchFeatureValues return cached');
    return cachedFlags;
  }

  async function getCachedOrFetchFeatureValue(
    featureName: FeatureName,
  ): Promise<FeatureValue> {
    logger.debug('getCachedOrFetchFeatureValue() - %s', featureName);
    const isFeatureKnown = featuresList.find(
      (feature) => feature.featureName === featureName,
    );
    if (!isFeatureKnown) {
      throw new Error(`Unknown feature "${featureName}"`);
    }

    const flags = await getAllCachedOrFetchFeatureValues();
    const flag = flags.find((feature) => feature.featureName === featureName);

    if (!flag) {
      logger.warn('known feature not present in feature list: %s', featureName);
      // default to off
      return undefined;
    }

    logger.debug(
      'getCachedOrFetchFeatureValue() - returning %s =',
      featureName,
      flag.overrideValue ?? flag.value,
    );

    return flag.overrideValue ?? flag.value;
  }

  //
  // raw storage manipulation
  //----------------------------------------------------------------------------
  async function getCachedFlags(): Promise<FeatureFlag[]> {
    logPageLoadMilestone('getCachedFlags start');
    const value = await rx.firstValueFrom(currentValue$.pipe(op.take(1)));
    logPageLoadMilestone('getCachedFlags end');
    return value;
  }

  async function readCachedFeatureFlags(): Promise<FeatureFlag[]> {
    logger.debug('readCachedFeatureFlags()');
    const raw = await storage.get(CACHE_KEY).then((value) => {
      return value ? (JSON.parse(value) as FeatureFlag[]) : [];
    });
    logger.debug('readCachedFeatureFlags() - returning %d flags', raw.length);
    return raw;
  }

  async function writeCachedFeatureFlags(flags: FeatureFlag[]): Promise<void> {
    logger.debug('writeCachedFeatureFlags() - writing %d flags', flags.length);
    return storage.set(CACHE_KEY, JSON.stringify(flags), EXPIRY_SECONDS);
  }

  async function clearCachedFeatureValues(): Promise<void> {
    logger.debug('clearCachedFeatureValues()');
    // mark that we no longer have a cache and the next flag lookup should
    // trigger a refresh internally
    shouldMakeRequestToFetchFlags = true;
    currentValue$.next([]);
    return storage.delete(CACHE_KEY);
  }

  async function tearDown(): Promise<void> {
    await clearCachedFeatureValues();
    cancelSyncFeatureMemCache();
    await cancelSyncFeatureFlags();
    await ringService.tearDown();
  }

  logoutService.registerLogoutCallback(ServiceId.EXPERIMENTATION, async () => {
    logger.debug('Handling logout in experimentation service');
    await tearDown();
    logger.debug('Done handling logout in experimentation service');
  });

  return services.provide(
    ServiceId.EXPERIMENTATION,
    {
      getAllExperimentationAttributes,
      setExperimentationAttributes,
      getCachedFlags,
      refreshAllFeatureValues,
      clearCachedFeatureValues,
      getAllCachedOrFetchFeatureValues,
      getCachedOrFetchFeatureValue,
      setFeatureOverride,
      resetAllFeatureOverrides,
      startSyncFeatureFlags,
      cancelSyncFeatureFlags,
      listenForFeatures,
      startSyncFeatureMemCache,
      cancelSyncFeatureMemCache,
    },
    [
      ServiceId.DASH_AUTH,
      ServiceId.OPERATIONAL_METRICS,
      ServiceId.FEATURE_RING_SETTINGS,
    ],
  );
}
