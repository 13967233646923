import { tagged } from '@mirage/service-logging';
import {
  ComposeArtifact,
  ComposeArtifactMarkdownDraft,
  ComposeAssistantConversationMessage,
  ComposeAssistantDraftConfig,
  ComposeSession,
  ComposeSource,
  getFirstMarkdownArtifact,
  getSourceUUID,
  InputContext,
  isComposeAssistantConversationMessageMessage,
} from '@mirage/shared/compose/compose-session';
import Sentry from '@mirage/shared/sentry';
import { Reducer } from 'react';
import { v4 as uuidv4 } from 'uuid';

const logger = tagged('ComposeAssistantReducer');

export interface ComposeCurrentSessionState {
  currentSession: Omit<ComposeSession, 'lastUpdated'>;
  inputContext: InputContext | undefined;
  isWaitingForResponse: boolean;
  progressString: string | undefined;
}

export type ComposeCurrentSessionAction =
  | {
      type: 'addMessage';
      message: ComposeAssistantConversationMessage;
      requiresResponse: boolean;
      toSessionID?: string; // when not specified, message is for the current session
    }
  | {
      type: 'markMessageResponded';
      toSessionID: string;
    }
  | {
      type: 'markWaitingForResponse';
    }
  | {
      type: 'updateProgress';
      progressString: string | undefined;
    }
  | {
      type: 'addSource';
      source: ComposeSource;
    }
  | {
      type: 'removeSource';
      source: ComposeSource;
    }
  | {
      type: 'setMarkdownContent';
      content: string;
    }
  | {
      type: 'setDraftConfig';
      config: ComposeAssistantDraftConfig;
    }
  | {
      type: 'setInputContext';
      context: InputContext | undefined;
    }
  | {
      type: 'addArtifact';
      artifact: ComposeArtifact;
    }
  | {
      type: 'updateCurrentSession';
      session: ComposeSession;
    }
  | {
      type: 'newSession';
      history: ComposeAssistantConversationMessage[];
    }
  | {
      type: 'loadSession';
      session: ComposeSession;
    };

export const composeCurrentSessionReducer: Reducer<
  ComposeCurrentSessionState,
  ComposeCurrentSessionAction
> = (state, action) => {
  logger.info('ComposeAssistantReducer', action.type);
  switch (action.type) {
    case 'addMessage': {
      if (
        action.toSessionID &&
        state.currentSession.id !== action.toSessionID
      ) {
        return state;
      }
      const newMessage = action.message;
      addMessageLogging(newMessage);
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          messagesHistory: [
            ...state.currentSession.messagesHistory,
            newMessage,
          ],
        },
        isWaitingForResponse:
          state.isWaitingForResponse || action.requiresResponse,
      };
    }
    case 'markMessageResponded':
      if (state.currentSession.id !== action.toSessionID) {
        return state;
      }
      return {
        ...state,
        isWaitingForResponse: false,
        progressString: undefined,
      };
    case 'markWaitingForResponse':
      return {
        ...state,
        isWaitingForResponse: true,
      };
    case 'updateProgress':
      return {
        ...state,
        progressString: action.progressString,
      };
    case 'addSource': {
      const uuid = getSourceUUID(action.source);
      if (!uuid) {
        logger.error('failed to get uuid for source', action.source);
        return state;
      }
      const sourceUUIDs = new Set(
        state.currentSession.sources.map(getSourceUUID),
      );
      if (sourceUUIDs.has(uuid)) {
        return state; // no-op if source already exists
      }
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          sources: [...state.currentSession.sources, action.source],
        },
      };
    }
    case 'removeSource':
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          sources: state.currentSession.sources.filter(
            (s) => getSourceUUID(s) !== getSourceUUID(action.source),
          ),
        },
      };
    case 'setMarkdownContent': {
      const artifact: ComposeArtifactMarkdownDraft = getFirstMarkdownArtifact(
        state.currentSession.artifacts,
      ) || {
        id: uuidv4(),
        type: 'markdown_draft',
        markdownContent: '',
        draftConfig: {},
      };
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          artifacts: updatedArtifacts(state.currentSession.artifacts, {
            ...artifact,
            markdownContent: action.content,
          }),
        },
      };
    }
    case 'setDraftConfig': {
      const artifact: ComposeArtifactMarkdownDraft = getFirstMarkdownArtifact(
        state.currentSession.artifacts,
      ) || {
        id: uuidv4(),
        type: 'markdown_draft',
        markdownContent: '',
        draftConfig: {},
      };
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          artifacts: updatedArtifacts(state.currentSession.artifacts, {
            ...artifact,
            draftConfig: action.config,
          }),
        },
      };
    }
    case 'setInputContext':
      return {
        ...state,
        inputContext: action.context,
      };
    case 'addArtifact':
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          artifacts: [...state.currentSession.artifacts, action.artifact],
        },
      };
    case 'updateCurrentSession':
      return {
        ...state,
        currentSession: action.session,
      };
    case 'newSession': {
      return {
        currentSession: newSession(action.history),
        inputContext: undefined,
        isWaitingForResponse: false,
        progressString: undefined,
      };
    }
    case 'loadSession':
      return {
        currentSession: action.session,
        inputContext: undefined,
        isWaitingForResponse: false,
        progressString: undefined,
      };
    default:
      action satisfies never;
      throw new Error(
        `Unhandled action type: ${
          (action as ComposeCurrentSessionAction).type
        }`,
      );
  }
};

export function newSession(
  history: ComposeAssistantConversationMessage[],
): Omit<ComposeSession, 'lastUpdated'> {
  return {
    dataId: '',
    id: uuidv4(),
    messagesHistory: history,
    sources: [],
    artifacts: [],
  };
}

function updatedArtifacts(
  artifacts: ComposeArtifact[],
  newArtifact: ComposeArtifact,
) {
  // update artifact with matching id or add new artifact
  artifacts = artifacts.filter((a) => a.id !== newArtifact.id);
  return [...artifacts, newArtifact];
}

function addMessageLogging(message: ComposeAssistantConversationMessage) {
  // Log `[Message truncated for size]` leaking error
  // OTCA-126

  // Assistant response message
  if (
    message.role === 'assistant' &&
    isComposeAssistantConversationMessageMessage(message)
  ) {
    // message.rawPromptText contains `[Message truncated for size]` which is expected
    // when the `write_doc` action is invoked
    if (message.rawPromptText?.includes('[Message truncated for size]')) {
      // message.text should never contain `[Message truncated for size]`,
      // and if it does this is our bug
      if (message.text.includes('[Message truncated for size]')) {
        logger.error(
          '[Assistant] "[Message truncated for size]" in assistant response',
          message,
        );

        // Log to Sentry
        Sentry.withScope((scope) => {
          // Set the extra data
          scope.setTag('messageText', message.text);
          scope.setTag('rawPromptText', message.rawPromptText);
          scope.setTag('messageRole', message.role);
          scope.setTag('messageType', message.type);
          scope.setTag('messageTimestamp', message.ts.toString());
          scope.setTag(
            'actionContextType',
            message.actionContext?.type || 'none',
          );
          scope.setTag(
            'ignoreMessageForPrompt',
            message.ignoreMessageForPrompt,
          );
          scope.setTag(
            'followUpSuggestions',
            message.followUpSuggestions?.join(',') || 'none',
          );
          scope.setTag(
            'referencingSources',
            message.referencingSources
              ?.map((source) => source.type)
              .join(',') || 'none',
          );

          // Capture the message
          Sentry.captureMessage(
            '[Assistant] "[Message truncated for size]" in assistant response',
            'error',
            {},
            // Ensure scope is passed in here
            scope,
          );
        });
      }
    }
  }
}
