import {
  ComposeAssistantConversationMessage,
  ComposeSource,
} from '@mirage/shared/compose/compose-session';

export interface ComposeVoice {
  id: string;
  dataId: string;
  name: string;
  description: string;
  sources: ComposeSource[];
  messagesHistory?: ComposeAssistantConversationMessage[];
  redraftedSamples?: ComposeVoiceSample[];
  originalSamples?: ComposeVoiceSample[];
  rev: number;
  accessType: AccessType;
  is_owned?: boolean;
}

export enum AccessType {
  INDIVIDUAL = 'individual',
  TEAM = 'team',
}

export interface ComposeVoiceSample {
  contentType: ComposeVoicePreviewContentType;
  text: string;
}

export enum ComposeVoicePreviewContentType {
  CASE_STUDY = 'Case Study',
  SALES_EMAIL = 'Sales Email',
  COMPANY_NEWSLETTER = 'Company Newsletter',
  ANALYSIS_REPORT = 'Analysis Report',
  BLOG_POST = 'Blog Post',
}

// TODO (will) only using 3 samples until we can figure out a way to do 5 in a timely manner
// might be worth making an initial api call for the first and then lazy loading the rest
export const composeVoiceSamples: ComposeVoiceSample[] = [
  // {
  //   contentType: ComposeVoicePreviewContentType.CASE_STUDY,
  //   text: `ABC partnered with Zenfinity, a mid-sized logistics firm facing operational delays and customer dissatisfaction due to outdated systems. Over three months, ABC implemented its cutting-edge workflow automation software, reducing Zenfinity’s processing time by 35%. The transition was managed with minimal downtime, and within weeks, Zenfinity reported a 22% increase in customer satisfaction and a 15% boost in employee productivity. By enhancing Zenfinity's tracking and reporting systems, ABC helped streamline end-to-end logistics. This partnership demonstrates ABC’s commitment to empowering clients with scalable, impactful solutions that drive both efficiency and customer success.`,
  // },
  {
    contentType: ComposeVoicePreviewContentType.SALES_EMAIL,
    text: `Subject: Streamline Your Operations with ABC's Proven Solutions!\n\nHi [Client Name],\n\nI hope you're well! As companies like yours face pressure to optimize operations, I wanted to introduce you to ABC’s automation platform, designed to cut costs and maximize productivity with minimal disruption. Our solutions are tailored for quick implementation, ensuring you see real results in weeks, not months. Just ask our clients, who’ve experienced up to a 35% reduction in processing times.\n\nWould you be open to a quick call next week to explore how ABC could enhance your team’s efficiency? Looking forward to connecting!\n\nBest,\n[Your Name]\nSales Manager, ABC`,
  },
  {
    contentType: ComposeVoicePreviewContentType.COMPANY_NEWSLETTER,
    text: `Subject: Welcoming 2024 with New Goals and Team Milestones\n\nDear Team,\n\nAs we look ahead to 2024, I’m thrilled to share some recent accomplishments and new initiatives. Over the past quarter, ABC exceeded our goal of a 30% customer satisfaction increase, thanks to your hard work and commitment. In Q1, we’ll launch our updated client portal—an upgrade that promises smoother user experiences and stronger data insights.\n\nOur goal for this year is ambitious but achievable: to continue leading in process automation while deepening our customer relationships. Each of you is critical to our success, and together, I’m confident we’ll make 2024 our best year yet!\n\nBest,\n[CEO Name]\nCEO, ABC`,
  },
  {
    contentType: ComposeVoicePreviewContentType.ANALYSIS_REPORT,
    text: `Subject: Q3 Market Trends in Enterprise Automation\n\nOverview: ABC’s Q3 analysis highlights significant growth in demand for enterprise automation, driven by increased competition and the need for operational efficiency. Analysis of industry-wide data shows that 76% of companies are prioritizing automation investments, particularly in finance and customer service. With competitor innovations emerging in predictive analytics, ABC is well-positioned to gain market share by expanding our AI-driven solutions in these areas.\n\nKey Takeaways:\n- Market Growth: Automation market projected to grow by 8% in 2024, primarily in the logistics and healthcare sectors.\n- Strategic Opportunity: ABC can capture increased demand by investing in predictive analytics and enhanced customer support features.\n- Recommendations include accelerating R&D efforts and reinforcing partnerships in high-growth verticals to maintain our competitive advantage.`,
  },
  // {
  //   contentType: ComposeVoicePreviewContentType.BLOG_POST,
  //   text: `Title: How Automation is Transforming the Future of Work\n\nThe automation landscape is evolving rapidly, and businesses are feeling the impact in ways both big and small. At ABC, we’ve seen firsthand how automation can unlock efficiencies, enhance customer experiences, and ultimately drive growth. For instance, automation in data processing alone has reduced routine administrative tasks by as much as 40%, freeing teams to focus on strategic initiatives.\n\nBut this transformation goes beyond just efficiency; it’s about enabling organizations to rethink the way they operate. Companies that embrace automation are poised to lead their industries, achieving faster response times, higher customer satisfaction, and increased profitability. The future is here, and ABC is excited to help our clients harness these possibilities to build a more innovative tomorrow.`,
  // },
];

export const getVoiceModificationHistoryFromMessages = (
  messages: ComposeAssistantConversationMessage[],
) => {
  return messages
    .filter((m) => m.role === 'user')
    .filter((m) => m.type === 'message')
    .map((m) => m.text);
};
