import { RadioButton } from '@dropbox/dig-components/controls';
import { FormLabel, FormRow } from '@dropbox/dig-components/form_row';
import { Text } from '@dropbox/dig-components/typography';
import { AccessType } from '@mirage/shared/compose/compose-voice';
import i18n from '@mirage/translations';
import styles from './SourcesFromSettingsSharingToggle.module.css';

interface SourcesFromSettingsSharingToggleProps {
  sublabelText: string;
  accessType: AccessType;
  onAccessTypeChange: (newAccessType: AccessType) => void;
}
export function SourcesFromSettingsSharingToggle({
  sublabelText,
  accessType,
  onAccessTypeChange,
}: SourcesFromSettingsSharingToggleProps) {
  return (
    <>
      <Text
        size="small"
        tagName="div"
        className={styles.SourcesFromSettingsSharingToggleHeader}
        isBold
      >
        {i18n.t('compose_settings_from_sources_sharing_label')}
      </Text>
      <Text
        size="small"
        tagName="div"
        className={styles.SourcesFromSettingsSharingToggleLabel}
      >
        {sublabelText}
      </Text>
      <div className={styles.SourcesFromSettingsSharingToggleContainer}>
        <FormRow variant="control">
          <RadioButton
            className={styles.SourcesFromSettingsSharingToggleRadio}
            checked={accessType === AccessType.INDIVIDUAL}
            onChange={() => onAccessTypeChange(AccessType.INDIVIDUAL)}
          />
          <FormLabel variant="control">{i18n.t('only_me_label')}</FormLabel>
        </FormRow>
        <FormRow variant="control">
          <RadioButton
            className={styles.SourcesFromSettingsSharingToggleRadio}
            checked={accessType === AccessType.TEAM}
            onChange={() => onAccessTypeChange(AccessType.TEAM)}
          />
          <FormLabel variant="control">
            {i18n.t('publish_to_company_label')}
          </FormLabel>
        </FormRow>
      </div>
    </>
  );
}
SourcesFromSettingsSharingToggle.displayName =
  'SourcesFromSettingsSharingToggle';
