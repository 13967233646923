import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';
import { Service } from './service/provider';
import { FeatureRing } from './types';

const service = services.get<Service>(ServiceId.FEATURE_RING_SETTINGS);

export async function getFeatureRingSettings(refresh = false) {
  return await rx.firstValueFrom(service.getFeatureRingSettings(refresh));
}

export async function tearDown() {
  return await rx.firstValueFrom(service.tearDown());
}

export async function setOverride(ring: FeatureRing) {
  return await rx.firstValueFrom(service.setOverride(ring));
}

export async function clearOverride() {
  return await rx.firstValueFrom(service.clearOverride());
}

export async function getCurrentFeatureRing() {
  return await rx.firstValueFrom(service.getCurrentFeatureRing());
}

export async function startSyncFeatureRingSettings() {
  return await rx.firstValueFrom(service.startSyncFeatureRingSettings());
}

export async function cancelSyncFeatureRingSettings() {
  return await rx.firstValueFrom(service.cancelSyncFeatureRingSettings());
}

export async function getFeatureRingFromCacheForLogging() {
  return await rx.firstValueFrom(service.getFeatureRingFromCacheForLogging());
}
