import { dash } from '@dropbox/api-v2-client/types/dropbox_types';
import { IconButton } from '@dropbox/dig-components/buttons';
import { ProgressBar } from '@dropbox/dig-components/progress_indicators';
import {
  TextInput,
  TextInputInputRefObject,
} from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine, CloseLine, SearchLine } from '@dropbox/dig-icons/assets';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Click_AddSource } from '@mirage/analytics/events/types/click_add_source';
import { PAP_Interact_SourceInput } from '@mirage/analytics/events/types/interact_source_input';
import {
  ComposeSourceRow,
  ComposeSourceRowProps,
} from '@mirage/mosaics/ComposeAssistant/components/compose-sources/ComposeSourceRow';
import { callApiV2 } from '@mirage/service-dbx-api';
import { buildSummarizable } from '@mirage/service-dbx-api/service/utils';
import { tagged } from '@mirage/service-logging';
import { useTypeaheadSearch } from '@mirage/service-typeahead-search/hooks/useTypeaheadSearch';
import { ResultType } from '@mirage/service-typeahead-search/service/types';
import {
  ComposeSource,
  getSourceUUID,
} from '@mirage/shared/compose/compose-session';
import { Recommendation } from '@mirage/shared/search/recommendation';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import debounce from 'lodash.debounce';
import {
  ChangeEventHandler,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './SourcesSearch.module.css';

const logger = tagged('SourcesSearch');

interface SearchState {
  currentQuery: string;
  lastSearch?: {
    query: string;
    results: dash.query_result_unionSearchResult[];
  };
}

const VISIBLE_TYPEAHEAD_RESULTS = 3;
const VISIBLE_FULL_SEARCH_RESULTS = 10;
const VISIBLE_UNUSABLE_SEARCH_RESULTS = 10;

interface SourceSearchProps {
  aboveSearchResultsLabel?: string;
  aboveSearchResults?: React.ReactNode;
  existingSources: ComposeSource[];
  onAddSource: (source: ComposeSource) => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  hasBorder?: boolean;
  hideSearchResultsLabel?: boolean;
}
export const SourceSearch = memo(
  ({
    aboveSearchResultsLabel,
    aboveSearchResults,
    existingSources,
    onAddSource,
    logComposeEvent,
    hasBorder,
    hideSearchResultsLabel,
  }: SourceSearchProps) => {
    const inputRef = useRef<TextInputInputRefObject>(null);
    const [searchState, setSearchState] = useState<SearchState>({
      currentQuery: '',
    });
    const existingSourcesUUIDs = useMemo(
      () => new Set(existingSources.map((source) => getSourceUUID(source)!)),
      [existingSources],
    );
    const debouncedUpdateSearchResults = useMemo(() => {
      const updateSearchResults = async (query: string) => {
        logger.log('searching for query of length', query.length);
        const results = await getSearchResults(query);
        setSearchState((prev) => {
          if (prev.currentQuery === query) {
            return {
              ...prev,
              lastSearch: results,
            };
          }
          return prev;
        });
      };
      return debounce(updateSearchResults, 1000, {
        leading: true,
        trailing: true,
      });
    }, []);
    const debouncedLogEvent = useMemo(() => {
      return debounce(
        (query: string) => {
          logComposeEvent(
            PAP_Interact_SourceInput({
              queryString: query,
            }),
          );
        },
        1000,
        { trailing: true },
      );
    }, [logComposeEvent]);
    const handleChangeQuery: ChangeEventHandler<HTMLInputElement> = (e) => {
      const query = e.target.value;
      setSearchState((prev) => {
        if (prev.currentQuery === query) {
          return prev;
        }
        return {
          ...prev,
          currentQuery: query,
        };
      });
      debouncedUpdateSearchResults(query);
      debouncedLogEvent(query);
    };
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);
    const typeaheadSearch = useTypeaheadSearch({
      isInstanced: true,
      featureLine: 'compose',
    });
    const typeaheadSearchRef = useRef(typeaheadSearch);
    typeaheadSearchRef.current = typeaheadSearch;
    useEffect(() => {
      typeaheadSearchRef.current.setTypeaheadQuery(
        searchState.currentQuery,
        false,
      );
      typeaheadSearchRef.current.setCanShowTypeahead(true);
    }, [searchState.currentQuery]);
    const typeaheadResults: Recommendation[] = useMemo(() => {
      const results: Recommendation[] = [];
      for (const typeaheadResult of typeaheadSearch.typeaheadResults) {
        if (
          typeaheadResult.type === ResultType.Recommendation &&
          typeaheadResult.result.summarizable === 'yes_summarizable'
        ) {
          results.push(typeaheadResult.result);
        }
      }
      logger.log('typeaheadSearch results length:', results.length);
      return results;
    }, [typeaheadSearch.typeaheadResults]);

    const fullSearchResults = useMemo(() => {
      const results = searchState.lastSearch?.results || [];
      const typeaheadUUIDs = new Set(typeaheadResults.map((r) => r.uuid));
      return results
        .filter((r) => buildSummarizable(r.summarizable) === 'yes_summarizable')
        .filter((r) => r.uuid && !typeaheadUUIDs.has(r.uuid));
    }, [searchState.lastSearch?.results, typeaheadResults]);

    const unusableResults = useMemo(() => {
      const results = searchState.lastSearch?.results || [];
      return results.filter(
        (r) => buildSummarizable(r.summarizable) !== 'yes_summarizable',
      );
    }, [searchState.lastSearch?.results, typeaheadResults]);

    const containerRef = useRef<HTMLDivElement>(null);
    const handleScroll = useMemo(
      () =>
        debounce(
          () => {
            const container = containerRef.current;
            if (!container) return;
            setIsScrolled(container.scrollTop > 0);
          },
          100,
          { leading: true },
        ),
      [],
    );
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
      const container = containerRef.current;
      if (!container) return;
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }, [handleScroll]);
    return (
      <div
        className={classnames(styles.SourcesSearchContainer, {
          [styles.SourcesSearchContainerBordered]: hasBorder,
        })}
      >
        <div
          className={classnames(styles.SourcesSearchInput, {
            [styles.SourcesSearchInputBorder]: isScrolled,
          })}
        >
          <TextInput.Container>
            <UIIcon
              src={SearchLine}
              className={styles.SourcesSearchInputIcon}
              aria-label={i18n.t('icon_for_search')}
            />
            <TextInput.Input
              className={styles.SourcesSearchInputField}
              placeholder={i18n.t('compose_sources_search_input_placeholder')}
              size={12}
              onChange={handleChangeQuery}
              ref={inputRef}
            />
            {searchState.currentQuery.length > 0 && (
              <IconButton
                variant="transparent"
                size="small"
                onClick={() => {
                  if (inputRef.current) {
                    inputRef.current.value = '';
                    setSearchState({
                      currentQuery: '',
                    });
                  }
                }}
              >
                <UIIcon src={CloseLine} />
              </IconButton>
            )}
            <div className={styles.SourcesSearchProgressBar}>
              {searchState.currentQuery.length > 0 &&
                searchState.currentQuery !== searchState.lastSearch?.query && (
                  <ProgressBar in isIndeterminate />
                )}
            </div>
          </TextInput.Container>
        </div>
        <div ref={containerRef} className={styles.SourcesSearchResults}>
          {aboveSearchResultsLabel && (
            <Text
              color="subtle"
              size="small"
              tagName="div"
              className={styles.SourcesSectionLabelAbove}
            >
              {aboveSearchResultsLabel}
            </Text>
          )}
          {aboveSearchResults}
          {!hideSearchResultsLabel && (
            <Text
              color="subtle"
              size="small"
              tagName="div"
              className={styles.SourcesSectionLabel}
            >
              {searchState.currentQuery.length > 0
                ? i18n.t('compose_search_results_title')
                : i18n.t('compose_recommendations_title')}
            </Text>
          )}
          {(fullSearchResults.length > 0 || typeaheadResults.length > 0) && (
            <div className={styles.SourceSearchResultRows}>
              {typeaheadResults
                .slice(0, VISIBLE_TYPEAHEAD_RESULTS)
                .filter((result) => !existingSourcesUUIDs.has(result.uuid))
                .map((result, i) => {
                  const source: ComposeSource = {
                    type: 'recommendation',
                    recommendation: result,
                  };
                  const action: ComposeSourceRowProps['action'] = {
                    icon: <UIIcon src={AddLine} />,
                    variant: 'circle',
                    onClick: () => {
                      logComposeEvent(PAP_Click_AddSource());
                      onAddSource(source);
                    },
                    label: i18n.t('compose_source_row_add_button_label'),
                  };
                  return (
                    <ComposeSourceRow
                      key={`typeahead-result-${getSourceUUID(source) || i}`}
                      source={source}
                      action={action}
                      variant={hasBorder ? 'small' : 'default'}
                    />
                  );
                })}
              {fullSearchResults
                .slice(0, VISIBLE_FULL_SEARCH_RESULTS)
                .filter(
                  (result) => !existingSourcesUUIDs.has(result.uuid || ''),
                )
                .map((result, i) => {
                  if (result.uuid === undefined) {
                    return null;
                  }
                  const source: ComposeSource = {
                    type: 'dash_search_result',
                    searchResult: result,
                  };
                  const action: ComposeSourceRowProps['action'] = {
                    icon: <UIIcon src={AddLine} />,
                    variant: 'circle',
                    onClick: () => {
                      logComposeEvent(PAP_Click_AddSource());
                      onAddSource(source);
                    },
                    label: i18n.t('compose_source_row_add_button_label'),
                  };
                  return (
                    <ComposeSourceRow
                      key={`result-${getSourceUUID(source) || i}`}
                      source={source}
                      action={action}
                      variant={hasBorder ? 'small' : 'default'}
                    />
                  );
                })}
              {unusableResults
                .slice(0, VISIBLE_UNUSABLE_SEARCH_RESULTS)
                .map((result, i) => {
                  if (result.uuid === undefined) {
                    return null;
                  }
                  const source: ComposeSource = {
                    type: 'dash_search_result',
                    searchResult: result,
                  };
                  return (
                    <ComposeSourceRow
                      key={`result-${getSourceUUID(source) || i}`}
                      source={source}
                      action={undefined}
                      variant={hasBorder ? 'small' : 'default'}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
    );
  },
);
SourceSearch.displayName = 'SourceSearch';

const MIN_FULL_SEARCH_QUERY_LENGTH = 3;

async function getSearchResults(query: string) {
  if (query.length < MIN_FULL_SEARCH_QUERY_LENGTH) {
    return { query, results: [] };
  }
  const response = await callApiV2('dcsSearch', {
    query_text: query,
    type: { '.tag': 'do_not_include_answer' },
  });
  const results = response.results || [];
  return {
    query,
    results: results
      .filter(
        (result) =>
          result.query_result &&
          result.query_result['.tag'] === 'search_result',
      )
      .map(
        (result) => result.query_result,
      ) as dash.query_result_unionSearchResult[],
  };
}
