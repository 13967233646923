import { SimpleTruncate } from '@dropbox/dash-component-library';
import { Avatar as DigAvatar } from '@dropbox/dig-components/avatar';
import { Badge } from '@dropbox/dig-components/badge';
import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { List, ListProps } from '@dropbox/dig-components/list';
import { Text } from '@dropbox/dig-components/typography';
import { Box, Split, Stack } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AddLine,
  AppsFill,
  AppsLine,
  CloseLine,
  CommentLine,
  DownloadLine,
  DropboxLine,
  HomeFill,
  HomeLine,
  MagicWandLine,
  SidebarHideLine,
  SidebarShowLine,
  StacksFill,
  StacksLine,
} from '@dropbox/dig-icons/assets';
import { WordmarkLogo } from '@dropbox/dig-logos';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Click_DashNewStack } from '@mirage/analytics/events/types/click_dash_new_stack';
import { PAP_Collapse_DashLeftSidebar } from '@mirage/analytics/events/types/collapse_dash_left_sidebar';
import { PAP_Expand_DashLeftSidebar } from '@mirage/analytics/events/types/expand_dash_left_sidebar';
import { PAP_Open_DashAssistant } from '@mirage/analytics/events/types/open_dash_assistant';
import { PAP_Open_DashConnectAppPage } from '@mirage/analytics/events/types/open_dash_connect_app_page';
import { PAP_Open_DashStartPage } from '@mirage/analytics/events/types/open_dash_start_page';
import { PAP_Open_StacksPage } from '@mirage/analytics/events/types/open_stacks_page';
import { PAP_Shown_DashNavigationSidebar } from '@mirage/analytics/events/types/shown_dash_navigation_sidebar';
import { PAP_Shown_DownloadDashUpsell } from '@mirage/analytics/events/types/shown_download_dash_upsell';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { DownloadDashUpsell } from '@mirage/growth/onboarding/download-dash-upsell/DownloadDashUpsell';
import useDownloadDashUpsell from '@mirage/growth/onboarding/download-dash-upsell/useDownloadDashUpsell';
import { DashStackIcon } from '@mirage/mosaics/DCLWrappers/DashStackIcon';
import { useMagicFlags } from '@mirage/mosaics/MagicFlags/useMagicFlags';
import { NavigationAvatar } from '@mirage/mosaics/NavigationAvatar';
import { typeaheadIsOpenAtom } from '@mirage/mosaics/SearchBarWithTypeahead/atoms';
import { useConnectorsUI } from '@mirage/mosaics/SettingsPage/useConnectorsUI';
import { useDevFeatures } from '@mirage/service-auth/useDevFeatures';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import {
  logPageLoadMilestone,
  logPageLoadMilestoneOnce,
} from '@mirage/service-operational-metrics/page-load';
import { useLastViewedStackInfo } from '@mirage/service-recent-content/hooks/useLastViewedStackInfo';
import { exit } from '@mirage/service-setting-actions';
import { stackGetShareId } from '@mirage/service-stacks/service/utils';
import { getInitials } from '@mirage/shared/account';
import { BetaBadge } from '@mirage/shared/badges/BetaBadge';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { useAdjustBreakpointClass } from '@mirage/shared/responsive/breakpoints';
import { useIsHoverFriendly } from '@mirage/shared/responsive/hover';
import { useIsMobileSizeForSidebar } from '@mirage/shared/responsive/mobile';
import { DigTooltip, DigTooltipProps } from '@mirage/shared/util/DigTooltip';
import { useModal } from '@mirage/shared/util/showModal';
import { stackGetName, stackIsPinned } from '@mirage/stacks/Helpers/Utils';
import {
  useLastViewedStacksWithItems,
  useSortedStacks,
} from '@mirage/stacks/hooks';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import merge from 'lodash/merge';
import noop from 'lodash/noop';
import {
  forwardRef,
  ReactNode,
  SVGProps,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Link,
  NavLink,
  NavLinkRenderProps,
  useNavigate,
} from 'react-router-dom';
import { FeedbackModal } from '../Feedback';
import { useForceNavToNewStack } from '../NewStackPage/hooks';
import { useAdminUI } from '../SettingsPage/useAdminUI';
import { globalNavIsCollapsedAtom, globalNavIsMobileAtom } from './atoms';
import { DashWordmarkText } from './DashWordmarkText';
import styles from './GlobalNav.module.css';
import { useDashTeamLogoUrl, useViewDashStackEvent } from './hooks';
import { GlobalNavTab } from './types';
import { getFeatureLine } from './utils';

import type { stacks } from '@dropbox/api-v2-client';

const GlobalNavTooltip = (props: DigTooltipProps) => (
  <DigTooltip placement="right" openDelay={500} {...props} />
);

type GlobalNavLinkProps = {
  label: string;
  to: string;
  state?: Record<string, boolean>;
  lineIcon: (props: SVGProps<SVGSVGElement>) => React.ReactElement;
  fillIcon: (props: SVGProps<SVGSVGElement>) => React.ReactElement;
  navTab: GlobalNavTab;
  showTooltip: boolean;
  iconBadge?: React.ReactElement;
  hasNotificationBadge?: boolean;
  onClick?: React.MouseEventHandler;
};

function getGlobalNavLinkItemClassNames({
  isActive,
}: NavLinkRenderProps): string {
  if (isActive) return `${styles.globalNavLinkItem} ${styles.active}`;
  return styles.globalNavLinkItem;
}

// Need to redefine this because CustomSpacingProps is not exported from Dig
type CustomSpacingProps = Pick<
  ListProps,
  | 'padding'
  | 'paddingTop'
  | 'paddingBottom'
  | 'paddingLeft'
  | 'paddingRight'
  | 'paddingX'
  | 'paddingY'
>;

const NavList = ({
  children,
  ...spacing
}: {
  children: ReactNode;
} & CustomSpacingProps) => {
  const vars = merge(
    { padding: 0, paddingLeft: 12, paddingRight: 12 },
    spacing,
  );

  return <List {...vars}>{children}</List>;
};

const NavBox = ({
  children,
  ...spacing
}: { children: ReactNode } & CustomSpacingProps) => {
  const vars = merge(
    { padding: 0, paddingLeft: 12, paddingRight: 12 },
    spacing,
  );

  return (
    <Box display="block" style={vars}>
      {children}
    </Box>
  );
};

const GlobalNavLink = forwardRef<HTMLAnchorElement, GlobalNavLinkProps>(
  (
    {
      label,
      to,
      state,
      lineIcon,
      fillIcon,
      navTab,
      showTooltip,
      onClick,
    }: GlobalNavLinkProps,
    ref,
  ) => {
    return (
      <List.Item isSelectable className={styles.globalNavListItem}>
        <GlobalNavTooltip title={label} disabled={!showTooltip}>
          <NavLink
            to={to}
            state={state}
            end
            ref={ref}
            className={getGlobalNavLinkItemClassNames}
            data-testid={`NavTab-${navTab}`}
            data-uxa-log={createUxaElementId(`${navTab}_nav_button`, {
              actionSurfaceComponent: 'sidebar',
              featureLine: getFeatureLine(navTab),
            })}
            data-campaigns-element-id={`dash-sidebar-${navTab}-button`}
            onClick={onClick}
          >
            {({ isActive }) => (
              <>
                <List.Accessory>
                  <Box display="flex">
                    <UIIcon width={24} src={isActive ? fillIcon : lineIcon} />
                  </Box>
                </List.Accessory>
                <List.Content className={styles.globalNavLinkItemContent}>
                  <Text color="inherit">{label}</Text>
                </List.Content>
              </>
            )}
          </NavLink>
        </GlobalNavTooltip>
      </List.Item>
    );
  },
);

GlobalNavLink.displayName = 'GlobalNavLink';

const GlobalNavStack = ({
  stack,
  showTooltip,
  actionSurfaceComponent,
  alwaysShowTooltip = false,
}: {
  stack: stacks.Stack;
  showTooltip: boolean;
  alwaysShowTooltip?: boolean;
  actionSurfaceComponent?: ActionSurfaceComponent;
}) => {
  const stackName = stackGetName(stack);
  const [isTruncated, setIsTruncated] = useState(showTooltip);

  const handleViewDashStackEvent = useViewDashStackEvent(stack);

  return (
    <List.Item isSelectable className={styles.globalNavListItem}>
      <GlobalNavTooltip
        title={stackName}
        // Don't show if we're not showing tooltips OR
        //   if the text isn't truncated AND
        //   we're not always showing tooltips due to the sidebar being collapsed
        disabled={!showTooltip || (!isTruncated && !alwaysShowTooltip)}
      >
        <NavLink
          to={`/stacks/${stackGetShareId(stack)}`}
          className={getGlobalNavLinkItemClassNames}
          data-uxa-log={createUxaElementId('sidebar_stack_button', {
            actionSurfaceComponent: 'sidebar',
            featureLine: 'stacks',
          })}
          onClick={() => handleViewDashStackEvent(actionSurfaceComponent)}
          data-testid="NavTab-stackButton"
        >
          <List.Accessory>
            <DashStackIcon
              size="small"
              colorIndex={stack.stack_data?.color_index}
              emoji={stack.stack_data?.emoji}
            />
          </List.Accessory>
          <List.Content className={styles.globalNavLinkItemContent}>
            <Text color="inherit">
              <SimpleTruncate
                onOverflowChange={({ isOverflowing }) =>
                  setIsTruncated(isOverflowing)
                }
                style={{ maxWidth: 168 }}
              >
                {stackName}
              </SimpleTruncate>
            </Text>
          </List.Content>
        </NavLink>
      </GlobalNavTooltip>
    </List.Item>
  );
};

const GlobalNavAvatar = ({
  showTooltip,
  hasAdminConsoleWarning,
  isExpanded,
  enableDevFeatures,
  enableAdminPublishedContentFeature,
}: {
  showTooltip: boolean;
  hasAdminConsoleWarning: boolean;
  isExpanded: boolean;
  enableDevFeatures: boolean;
  enableAdminPublishedContentFeature: boolean;
}) => {
  const account = useDropboxAccount();
  const navigate = useNavigate();
  const { openAdminSettings } = useAdminUI();

  const isDesktop = EnvCtx.surface == 'desktop';

  const handleLogout = useCallback((): void => {
    if (isDesktop) {
      exit('logout');
      return;
    }
    navigate('/logout');
  }, [isDesktop, navigate]);

  const collapsedAdminWarningBadge =
    !isExpanded && hasAdminConsoleWarning ? (
      <Badge variant="alert" className={styles.globalNavAvatarBadge}>
        {'!'}
      </Badge>
    ) : null;

  const expandedAdminWarningBadge =
    isExpanded && hasAdminConsoleWarning ? (
      <Badge
        variant="alert"
        className={classnames(styles.globalNavAvatarBadge, styles.expanded)}
      >
        {'!'}
      </Badge>
    ) : null;

  return (
    <GlobalNavTooltip title={i18n.t('account')} disabled={!showTooltip}>
      <NavigationAvatar
        menuPlacement="top-end"
        ariaRole="listitem"
        onAdminConsole={openAdminSettings}
        hasAdminConsoleWarning={hasAdminConsoleWarning}
        onSignOut={handleLogout}
        onSettings={() => {
          navigate('/settings');
        }}
        onFeatures={
          enableDevFeatures ? () => navigate('/settings/features') : undefined
        }
        onDeveloper={
          enableDevFeatures ? () => navigate('/settings/developer') : undefined
        }
        onPublishedContent={
          enableAdminPublishedContentFeature
            ? () => navigate('/published_content')
            : undefined
        }
        anchor={
          <Button
            type="button"
            variant="borderless"
            data-testid="avatar-container"
            data-uxa-log={createUxaElementId('avatar_button', {
              actionSurfaceComponent: 'avatar_dropdown',
            })}
            className={classnames(
              styles.globalNavListItem,
              styles.globalNavAvatarButton,
            )}
          >
            <Box display="flex" className={styles.globalNavBoxItem}>
              <Box>
                <Box display="flex">
                  {collapsedAdminWarningBadge}
                  <DigAvatar
                    src={account?.profile_photo_url}
                    hasNoOutline={true}
                    size="small"
                    className={styles.globalNavAvatarSrc}
                  >
                    {getInitials(account?.name?.display_name ?? '')}
                  </DigAvatar>
                </Box>
              </Box>
              <Box className={styles.globalNavLinkItemContent}>
                <Text color="inherit">
                  {i18n.t('account')}
                  {expandedAdminWarningBadge}
                </Text>
              </Box>
            </Box>
          </Button>
        }
      />
    </GlobalNavTooltip>
  );
};

export type BetaBadgePosition = 'top' | 'bottom' | 'none';

/** Handles only the view and animation aspects of the global nav. */
const GlobalNavView = ({
  stacks,
  betaBadgePosition = 'none',
  globalNavCollapsed,
  toggleCollapsed,
  setCollapsed,
  alwaysCollapsed,
  useMobileNav,
  createStack,
  appsLink,
  onAppsClick,
}: {
  stacks: stacks.Stack[] | undefined;
  betaBadgePosition: BetaBadgePosition;
  globalNavCollapsed: boolean;
  toggleCollapsed: () => void;
  setCollapsed: (val?: boolean) => void;
  alwaysCollapsed: boolean;
  useMobileNav: boolean;
  createStack: () => void;
  appsLink: string;
  onAppsClick: React.MouseEventHandler;
}) => {
  const isDarwinHornet =
    EnvCtx.platform === 'darwin' && EnvCtx.surface === 'desktop';
  const isWindowsHornet =
    EnvCtx.platform === 'win32' && EnvCtx.surface === 'desktop';
  const pinnedStacks = stacks?.filter((stack) => stackIsPinned(stack));
  const lastViewedStackInfo = useLastViewedStackInfo();
  const { stacks: lastViewedStacks } =
    useLastViewedStacksWithItems(lastViewedStackInfo);
  const { showModal, hideModal } = useModal();
  const downloadDashUpsell = useDownloadDashUpsell();
  const downloadDashUpsellAnchor = useRef(null);
  const enableCompose =
    useFeatureFlagValue('dash_assist_2024_09_06_compose_v1') === 'ON' &&
    EnvCtx.surface === 'web'; // Only show on web -- need to update layout for other surfaces before releasing
  const enableDevFeatures = useDevFeatures();
  useMagicFlags();

  const enablePublishedContent = convertFeatureValueToBool(
    useFeatureFlagValue('dash_web_2024_09_24_published_content_is_admin'),
  );

  const showCompanyStacks = convertFeatureValueToBool(
    useFeatureFlagValue('dash_web_2024_10_02_show_company_pinned_stacks'),
  );

  const isTypeaheadOpen = useAtomValue(typeaheadIsOpenAtom);
  const [hoverNav, setHoverNav] = useState(false);
  const [hasFadeOutOverlay, setHasFadeOutOverlay] = useState(false);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const teamDashLogoUrl = useDashTeamLogoUrl();

  const mobileCloseButtonRef = useRef<HTMLDivElement>(null);

  // Expanded applies only in non-mobile mode.
  const showExpanded = !globalNavCollapsed && !useMobileNav;

  // Mobile expanded is rendered as hovered.
  const showHovered = hoverNav || (!globalNavCollapsed && useMobileNav);

  // Mobile collapsed nav is invisible.
  const showMobileCollapsed = useMobileNav && globalNavCollapsed;

  const showTooltipForNavLink = alwaysCollapsed && !useMobileNav;
  const showTooltipForNavStack = !useMobileNav;

  const account = useDropboxAccount();
  const { unhealthyAdminConnections } = useConnectors();
  const isAdmin = Boolean(account?.team?.team_user_permissions?.is_admin);

  const enableAdminPublishedContentFeature =
    enablePublishedContent || (isAdmin && showCompanyStacks);

  // Reset the collapsed state when switching between mobile and desktop nav.
  const previousUseMobileNav = useRef(useMobileNav);
  useEffect(() => {
    if (useMobileNav !== previousUseMobileNav.current) {
      previousUseMobileNav.current = useMobileNav;
      setCollapsed(true);
    }
  }, [useMobileNav, setCollapsed]);
  function toggle() {
    toggleCollapsed();
    setHoverNav(globalNavCollapsed);

    if (useMobileNav && !globalNavCollapsed) {
      setHasFadeOutOverlay(true);
      setTimeout(() => setHasFadeOutOverlay(false), 500);
    }
  }

  function onDownloadClick(e: SyntheticEvent<HTMLDivElement>) {
    // Ensure that if the global nav is open on mobile that the nav remains open after click
    // The download upsell is only displayed when the global nav is open
    e.stopPropagation();

    setCollapsed(false);
    downloadDashUpsell.onOpen(true);
    reportPapEvent(PAP_Shown_DownloadDashUpsell({ featureLine: 'global_nav' }));
  }

  // Focus the close button when the mobile nav opens for tab navigation
  useEffect(() => {
    if (useMobileNav && !globalNavCollapsed) {
      mobileCloseButtonRef.current?.focus();
    }
  }, [useMobileNav, globalNavCollapsed]);

  return (
    <>
      <Box
        className={classnames(
          styles.globalNav,
          showExpanded && styles.expanded,
          showHovered && styles.hovered,
          showMobileCollapsed && styles.mobileCollapsed,
          alwaysCollapsed && styles.alwaysCollapsed,
          alwaysCollapsed || styles.notAlwaysCollapsed,
          isWindowsHornet && styles.windowsHornet,
          isDarwinHornet && styles.darwinHornet,
          isTypeaheadOpen && styles.disableMouseEvents,
        )}
        role={showMobileCollapsed ? 'presentation' : undefined}
        tabIndex={showMobileCollapsed ? -1 : undefined}
        aria-hidden={showMobileCollapsed}
        style={showMobileCollapsed ? { visibility: 'hidden' } : undefined}
        onMouseOver={() =>
          !useMobileNav &&
          !alwaysCollapsed &&
          globalNavCollapsed &&
          setHoverNav(true)
        }
        onMouseLeave={() =>
          !useMobileNav &&
          !alwaysCollapsed &&
          globalNavCollapsed &&
          setHoverNav(false)
        }
        onClick={(e: SyntheticEvent<HTMLDivElement>) => {
          // Auto-close when anything is clicked in mobile mode.
          if (useMobileNav && !globalNavCollapsed) {
            toggle();
          }

          // Don't let the click bleed through to the bottom.
          e.stopPropagation();
        }}
      >
        <Box>
          <NavBox>
            <Split
              display="flex"
              alignY="center"
              alignX="between"
              // Inline styling since DIG applies classname to wrong element
              style={{ width: '100%' }}
            >
              <Split.Item>
                <Box>
                  {useMobileNav ? (
                    <div className={styles.mobileCloseButton}>
                      <IconButton
                        variant="transparent"
                        // Let the auto-close onClick close the nav.
                        // onClick={toggle}
                        data-uxa-log={createUxaElementId('collapse_button', {
                          actionSurfaceComponent: 'sidebar',
                        })}
                        aria-label={i18n.t('collapse_nav_aria')}
                        aria-expanded={!globalNavCollapsed}
                        ref={mobileCloseButtonRef}
                        tabIndex={0}
                      >
                        <UIIcon src={CloseLine} />
                      </IconButton>
                    </div>
                  ) : (
                    !alwaysCollapsed && (
                      <GlobalNavTooltip
                        title={i18n.t('home')}
                        disabled={!showTooltipForNavLink}
                      >
                        <Box style={{ padding: 0 }}>
                          <Link
                            to="/"
                            data-uxa-log={createUxaElementId(
                              'dash_logo_button',
                              {
                                actionSurfaceComponent: 'sidebar',
                              },
                            )}
                            className={styles.globalNavLogoParent}
                          >
                            <div className={styles.globalNavLogo}>
                              {teamDashLogoUrl ? (
                                <img
                                  src={teamDashLogoUrl}
                                  className={styles.globalNavIcon}
                                  alt={i18n.t('company_logo_alt')}
                                />
                              ) : (
                                <UIIcon
                                  src={DropboxLine}
                                  className={styles.globalNavIcon}
                                />
                              )}
                              {betaBadgePosition === 'top' && (
                                <BetaBadge
                                  excludeDfb
                                  className={styles.betaLogo}
                                />
                              )}
                            </div>
                            {alwaysCollapsed || (
                              <div className={styles.globalNavWordmark}>
                                <WordmarkLogo
                                  size={40}
                                  src={DashWordmarkText}
                                />
                                {betaBadgePosition === 'top' && (
                                  <BetaBadge excludeDfb />
                                )}
                              </div>
                            )}
                          </Link>
                        </Box>
                      </GlobalNavTooltip>
                    )
                  )}
                </Box>
              </Split.Item>
              {!useMobileNav && !alwaysCollapsed && (
                <Split.Item>
                  <Box>
                    <IconButtonWithTooltip
                      tooltipProps={{
                        title: globalNavCollapsed
                          ? i18n.t('expand')
                          : i18n.t('collapse'),
                      }}
                      className={styles.expandButton}
                      variant="transparent"
                      onClick={toggle}
                      aria-expanded={!globalNavCollapsed}
                      data-uxa-log={createUxaElementId(
                        globalNavCollapsed
                          ? 'expand_button'
                          : 'collapse_button',
                        {
                          actionSurfaceComponent: 'sidebar',
                        },
                      )}
                    >
                      {globalNavCollapsed ? (
                        <UIIcon src={SidebarShowLine} />
                      ) : (
                        <UIIcon src={SidebarHideLine} />
                      )}
                    </IconButtonWithTooltip>
                  </Box>
                </Split.Item>
              )}
            </Split>
          </NavBox>
          <Box paddingTop="Micro Small">
            <NavList>
              <GlobalNavLink
                label={i18n.t('home')}
                to="/"
                lineIcon={HomeLine}
                fillIcon={HomeFill}
                navTab="home"
                showTooltip={showTooltipForNavLink}
                onClick={() =>
                  reportPapEvent(
                    PAP_Open_DashStartPage({ featureLine: 'global_nav' }),
                  )
                }
              />

              <GlobalNavLink
                label={i18n.t('stacks')}
                to="/stacks"
                lineIcon={StacksLine}
                fillIcon={StacksFill}
                navTab="stacks"
                showTooltip={showTooltipForNavLink}
                onClick={() =>
                  reportPapEvent(
                    PAP_Open_StacksPage({ featureLine: 'global_nav' }),
                  )
                }
              />

              {enableCompose && (
                <GlobalNavLink
                  label={i18n.t('assistant')}
                  to="/chat"
                  state={{ reset: true }}
                  lineIcon={MagicWandLine}
                  fillIcon={MagicWandLine}
                  navTab="chat"
                  showTooltip={showTooltipForNavLink}
                  onClick={() => {
                    reportPapEvent(
                      PAP_Open_DashAssistant({ featureLine: 'global_nav' }),
                    );
                  }}
                />
              )}
            </NavList>
          </Box>
        </Box>
        <Box paddingTop="Micro Large">
          {stacks && (
            <NavList>
              <GlobalNavTooltip
                title={i18n.t('create_stack')}
                disabled={!showTooltipForNavLink}
              >
                <List.Item
                  isSelectable
                  tagName="button"
                  className={classnames(
                    styles.globalNavAddStackBtn,
                    alwaysCollapsed && styles.alwaysCollapsed,
                  )}
                  onClick={createStack}
                  data-testid="NavTab-createStack"
                  aria-label={i18n.t('create_stack')}
                >
                  <List.Accessory>
                    <div
                      className={classnames(
                        styles.globalNavAddStackBtnIcon,
                        styles.globalNavIconBorder,
                      )}
                    >
                      <UIIcon size="small" src={AddLine} />
                    </div>
                  </List.Accessory>
                  {alwaysCollapsed || (
                    <List.Content>
                      <Text color="inherit">{i18n.t('create_stack')}</Text>
                    </List.Content>
                  )}
                </List.Item>
              </GlobalNavTooltip>
            </NavList>
          )}
        </Box>
        <Box
          paddingTop="Micro Small"
          className={classnames(
            styles.globalNavStacks,
            alwaysCollapsed && styles.alwaysCollapsed,
          )}
        >
          <NavList paddingRight={alwaysCollapsed ? 0 : undefined}>
            <List.Label
              withText={i18n.t('pinned')}
              className={styles.globalNavStacksLabel}
            />
            {pinnedStacks &&
              pinnedStacks.map((stack) => (
                <GlobalNavStack
                  key={stack.namespace_id ?? ''}
                  stack={stack}
                  showTooltip={showTooltipForNavStack}
                  alwaysShowTooltip={alwaysCollapsed}
                  actionSurfaceComponent="sidebar_pinned"
                />
              ))}
          </NavList>
          {!alwaysCollapsed && (
            <NavList paddingTop={8}>
              <List.Label
                withText={i18n.t('recent')}
                className={styles.globalNavStacksLabel}
              />
              {lastViewedStacks &&
                lastViewedStacks
                  .slice(0, 3)
                  .map((stack) => (
                    <GlobalNavStack
                      key={stack.namespace_id ?? ''}
                      stack={stack}
                      showTooltip={showTooltipForNavStack}
                      alwaysShowTooltip={alwaysCollapsed}
                      actionSurfaceComponent="sidebar_recent"
                    />
                  ))}
            </NavList>
          )}
        </Box>
        <Box paddingTop="Micro XLarge" paddingBottom="Micro Large">
          <Stack>
            <NavList>
              <GlobalNavTooltip
                title={i18n.t('settings_send_feedback')}
                disabled={!showTooltipForNavLink}
              >
                <List.Item
                  isSelectable
                  tagName="button"
                  className={classnames(
                    styles.globalNavAddStackBtn,
                    alwaysCollapsed && styles.alwaysCollapsed,
                  )}
                  onClick={() =>
                    showModal(<FeedbackModal onClose={hideModal} />)
                  }
                  data-testid="NavTab-sendFeedback"
                  button-name={'send feedback'}
                  aria-label={i18n.t('settings_send_feedback')}
                  role="button"
                >
                  <List.Accessory aria-hidden>
                    <div className={styles.globalNavAddStackBtnIcon}>
                      <UIIcon src={CommentLine} />
                    </div>
                  </List.Accessory>
                  {alwaysCollapsed || (
                    <List.Content>
                      <Text color="inherit">
                        {i18n.t('settings_send_feedback')}
                      </Text>
                    </List.Content>
                  )}
                </List.Item>
              </GlobalNavTooltip>
              {downloadDashUpsell.enabled && !alwaysCollapsed && (
                <>
                  <DownloadDashUpsell
                    {...downloadDashUpsell}
                    anchor={downloadDashUpsellAnchor}
                  />
                  <GlobalNavTooltip
                    title={i18n.t('download')}
                    disabled={!showTooltipForNavLink}
                  >
                    <List.Item
                      isSelectable
                      tagName="button"
                      ref={downloadDashUpsellAnchor}
                      className={classnames(
                        styles.globalNavAddStackBtn,
                        alwaysCollapsed && styles.alwaysCollapsed,
                      )}
                      onClick={onDownloadClick}
                      data-testid="NavTab-downloadUpsell"
                    >
                      <List.Accessory>
                        <div className={styles.globalNavAddStackBtnIcon}>
                          <UIIcon src={DownloadLine} />
                        </div>
                      </List.Accessory>
                      <List.Content>
                        <Text color="inherit" className={styles.showOnHover}>
                          {i18n.t('download')}
                        </Text>
                      </List.Content>
                    </List.Item>
                  </GlobalNavTooltip>
                </>
              )}
              <GlobalNavLink
                label={i18n.t('apps')}
                to={appsLink}
                lineIcon={AppsLine}
                fillIcon={AppsFill}
                navTab="apps"
                onClick={onAppsClick}
                showTooltip={showTooltipForNavLink}
              />

              {betaBadgePosition === 'bottom' ? (
                <Box
                  paddingTop="Micro Small"
                  paddingBottom="Micro Small"
                  className={styles.betaBadge}
                >
                  <BetaBadge excludeDfb overSubtle />
                </Box>
              ) : null}
              <GlobalNavAvatar
                showTooltip={showTooltipForNavLink}
                enableDevFeatures={enableDevFeatures}
                hasAdminConsoleWarning={
                  isAdmin && unhealthyAdminConnections.length > 0
                }
                isExpanded={showHovered || showExpanded}
                enableAdminPublishedContentFeature={
                  enableAdminPublishedContentFeature
                }
              />
            </NavList>
          </Stack>
        </Box>
      </Box>
      {useMobileNav && !globalNavCollapsed && (
        // The mobile fade overlay is not user-interactable.
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className={styles.mobileOverlayFadeIn} onClick={toggle} />
      )}
      {hasFadeOutOverlay && <div className={styles.mobileOverlayFadeOut} />}
    </>
  );
};

/** Handles external state and PAP events. */
export const GlobalNav = ({
  alwaysCollapsed,
  betaBadgePosition,
}: {
  alwaysCollapsed: boolean;
  betaBadgePosition: BetaBadgePosition;
}) => {
  logPageLoadMilestoneOnce('GlobalNav start');

  const stacks = useSortedStacks();
  const [isCollapsedState, setIsCollapsed] = useAtom(globalNavIsCollapsedAtom);
  const isCollapsed = isCollapsedState || alwaysCollapsed;
  const { appSettingsPath, openConnectors } = useConnectorsUI();
  const { reportPapEvent } = useMirageAnalyticsContext();

  useEffect(() => {
    if (stacks !== undefined) {
      logPageLoadMilestone(`GlobalNav has stacks: numStacks=${stacks.length}`);
    }
  }, [stacks]);

  // The sidebar affects the adjustment, so we have to use the unadjusted size
  // to ensure this value stays the same when expanding/collapsing the sidebar.
  const isMobileSize = useIsMobileSizeForSidebar();
  const isHoverFriendly = useIsHoverFriendly();
  const useMobileNav = !alwaysCollapsed && (isMobileSize || !isHoverFriendly);
  const forceNavToNewStack = useForceNavToNewStack();

  const setGlobalNavIsMobile = useSetAtom(globalNavIsMobileAtom);

  useEffect(() => {
    setGlobalNavIsMobile(useMobileNav);
  }, [setGlobalNavIsMobile, useMobileNav]);

  useAdjustBreakpointClass(useMobileNav ? 0 : isCollapsed ? 64 : 260);

  function toggleCollapsed() {
    setIsCollapsed((v) => !v);
    logCollapseChange();
  }

  function setCollapsed(collapsed: boolean) {
    setIsCollapsed(collapsed);
    logCollapseChange();
  }

  function logCollapseChange() {
    if (isCollapsed) {
      reportPapEvent(PAP_Expand_DashLeftSidebar({ featureLine: 'global_nav' }));
    } else {
      reportPapEvent(
        PAP_Collapse_DashLeftSidebar({ featureLine: 'global_nav' }),
      );
    }
  }

  function createStack() {
    reportPapEvent(
      PAP_Click_DashNewStack({
        stacksEmptyState: !stacks?.length,
        entryPoint: 'sidebar',
        featureLine: 'stacks',
      }),
    );

    forceNavToNewStack();
  }

  const pinnedStacks = (stacks || []).filter((stack) => stackIsPinned(stack));
  const numPinnedStacks = pinnedStacks.length;

  useEffect(() => {
    if (!isCollapsed) {
      reportPapEvent(
        PAP_Shown_DashNavigationSidebar({
          actionSurfaceComponent: 'sidebar',
          numPinnedStacks,
        }),
      );
    }
  }, [isCollapsed, reportPapEvent, numPinnedStacks]);

  return (
    <GlobalNavView
      stacks={stacks}
      globalNavCollapsed={Boolean(isCollapsed || alwaysCollapsed)}
      betaBadgePosition={betaBadgePosition}
      toggleCollapsed={alwaysCollapsed ? noop : toggleCollapsed}
      setCollapsed={alwaysCollapsed ? noop : setCollapsed}
      alwaysCollapsed={alwaysCollapsed}
      useMobileNav={useMobileNav}
      createStack={createStack}
      appsLink={appSettingsPath}
      onAppsClick={(e) => {
        reportPapEvent(
          PAP_Open_DashConnectAppPage({
            actionSurfaceComponent: 'sidebar',
            featureLine: 'global_nav',
          }),
        );
        openConnectors();
        e.preventDefault();
      }}
    />
  );
};
