import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalEditable } from '@lexical/react/useLexicalEditable';
import {
  $deleteTableColumn__EXPERIMENTAL,
  $deleteTableRow__EXPERIMENTAL,
  $getTableCellNodeFromLexicalNode,
  $getTableNodeFromLexicalNodeOrThrow,
  $insertTableColumn__EXPERIMENTAL,
  $insertTableRow__EXPERIMENTAL,
  $isTableCellNode,
  $isTableSelection,
  TableCellNode,
  TableNode,
  TableRowNode,
} from '@lexical/table';
import {
  AddColumnButtonHandle,
  AddRowButtonHandle,
  CellDispatchActionArg,
  ColumnActionHandle,
  ColumnDividerHandle,
  RowActionHandle,
  RowDividerHandle,
} from '@mirage/mosaics/ComposeAssistant/components/editor/table/TableHoverActionsHandles';
import { tagged } from '@mirage/service-logging';
import {
  $getNodeByKey,
  $getSelection,
  $hasAncestor,
  $isRangeSelection,
  $nodesOfType,
  LexicalEditor,
} from 'lexical';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { getRowsFromTable, moveTableNodes } from './tableUtils';

const logger = tagged('TableHoverActionsPlugin');

interface TableHoverActionsPluginProps {
  anchorElem: HTMLElement;
}
export const TableHoverActionsPlugin = memo(
  ({ anchorElem }: TableHoverActionsPluginProps) => {
    const isEditable = useLexicalEditable();
    return isEditable
      ? createPortal(
          <TableHoverActionsContainer anchorElem={anchorElem} />,
          anchorElem,
        )
      : null;
  },
);
TableHoverActionsPlugin.displayName = 'TableHoverActionsPlugin';

interface CellNodeInfo {
  cellNode: TableCellNode;
  cellElement: HTMLElement;
  tableElement: HTMLElement;
  isInFirstRow: boolean;
  isInFirstColumn: boolean;
  rowIndex: number;
  columnIndex: number;
}

interface TableHoverActionsContainerProps {
  anchorElem: HTMLElement;
}
export const TableHoverActionsContainer = memo(
  ({ anchorElem }: TableHoverActionsContainerProps) => {
    const [editor] = useLexicalComposerContext();
    const tableSelection = useTableSelection();
    const [tableCellNodesReversed, setTableCellNodesReversed] = useState<
      CellNodeInfo[]
    >([]);
    const [tableDimensions, setTableDimensions] = useState({
      rowCount: 0,
      columnCount: 0,
    });

    const resetTableState = useCallback(() => {
      setTableCellNodesReversed([]);
      setTableDimensions({ rowCount: 0, columnCount: 0 });
    }, []);

    useEffect(() => {
      if (!tableSelection?.table) {
        resetTableState();
        return;
      }

      editor.getEditorState().read(() => {
        const rows = getRowsFromTable(tableSelection.table);
        if (rows.length === 0) {
          resetTableState();
          return;
        }

        setTableDimensions({
          rowCount: rows.length,
          columnCount: rows[0].getChildren().length,
        });

        const getCellInfo = (cellNode: TableCellNode): CellNodeInfo | null => {
          const parentKey = cellNode.getParent()?.getKey();
          if (!parentKey) {
            logger.error('Parent key not found for table cell node');
            return null;
          }

          const row = $getNodeByKey(parentKey);
          if (!row || !(row instanceof TableRowNode)) {
            logger.error('Row not found for table cell node');
            return null;
          }

          const rowIndex = rows.indexOf(row);
          const columnIndex = row.getChildren().indexOf(cellNode);

          return {
            cellNode,
            cellElement: editor.getElementByKey(cellNode.getKey())!,
            tableElement: editor.getElementByKey(
              tableSelection.table.getKey(),
            )!,
            isInFirstRow: $isCellInFirstRow(cellNode),
            isInFirstColumn: $isCellInFirstColumn(cellNode),
            rowIndex,
            columnIndex,
          };
        };

        const validCells = $nodesOfType(TableCellNode)
          .filter((cellNode) => $hasAncestor(cellNode, tableSelection.table))
          .map(getCellInfo)
          .filter((info): info is CellNodeInfo => info !== null)
          .reverse();

        setTableCellNodesReversed(validCells);
      });
    }, [editor, tableSelection, resetTableState]);

    // NOTE: tableCellNodesReversed is reversed to ensure that the first row/column's handles are inserted
    // last in the DOM, so that they are rendered on top of the other handles to make sure their right-borders
    // are not covered by the other handles.
    return (
      <>
        {tableSelection && (
          <>
            <AddRowButtonHandle
              tableNode={tableSelection.table}
              tableElement={tableSelection.tableElement}
              anchorElement={anchorElem}
            />
            <AddColumnButtonHandle
              tableNode={tableSelection.table}
              tableElement={tableSelection.tableElement}
              anchorElement={anchorElem}
            />
          </>
        )}
        {tableCellNodesReversed.map(
          ({
            cellNode,
            cellElement,
            tableElement,
            isInFirstColumn,
            isInFirstRow,
            rowIndex,
            columnIndex,
          }) => {
            const handleDispatchAction = (arg: CellDispatchActionArg) => {
              editor.update(() => {
                cellNode?.selectEnd();
                switch (arg.action) {
                  case 'addColumn':
                    $insertTableColumn__EXPERIMENTAL(arg.order === 'after');
                    break;
                  case 'addRow':
                    $insertTableRow__EXPERIMENTAL(arg.order === 'after');
                    break;
                  case 'deleteColumn':
                    $deleteTableColumn__EXPERIMENTAL();
                    break;
                  case 'deleteRow':
                    $deleteTableRow__EXPERIMENTAL();
                    break;
                  case 'moveRow':
                  case 'moveColumn':
                    if (!tableSelection?.table) {
                      const message =
                        'Table not found when updating via moveRow or moveColumn action';
                      logger.error(message);
                      throw new Error(message);
                    }
                    moveTableNodes(
                      tableSelection.table,
                      arg.action === 'moveRow' ? 'row' : 'column',
                      arg.sourceIndex,
                      arg.targetIndex,
                    );
                    break;
                }
              });
            };
            return (
              <Fragment key={cellNode.getKey()}>
                {isInFirstRow && (
                  <>
                    <ColumnActionHandle
                      cellElement={cellElement}
                      tableElement={tableElement}
                      anchorElement={anchorElem}
                      dispatchAction={handleDispatchAction}
                      index={columnIndex}
                      totalCount={tableDimensions.columnCount}
                    />
                    {isInFirstColumn && (
                      <ColumnDividerHandle
                        placement="left"
                        cellElement={cellElement}
                        tableElement={tableElement}
                        anchorElement={anchorElem}
                        dispatchAction={handleDispatchAction}
                      />
                    )}
                    <ColumnDividerHandle
                      placement="right"
                      cellElement={cellElement}
                      tableElement={tableElement}
                      anchorElement={anchorElem}
                      dispatchAction={handleDispatchAction}
                    />
                  </>
                )}
                {isInFirstColumn && (
                  <>
                    <RowActionHandle
                      cellElement={cellElement}
                      tableElement={tableElement}
                      anchorElement={anchorElem}
                      dispatchAction={handleDispatchAction}
                      index={rowIndex}
                      totalCount={tableDimensions.rowCount}
                    />
                    {isInFirstRow && (
                      <RowDividerHandle
                        placement="above"
                        cellElement={cellElement}
                        tableElement={tableElement}
                        anchorElement={anchorElem}
                        dispatchAction={handleDispatchAction}
                      />
                    )}
                    <RowDividerHandle
                      placement="below"
                      cellElement={cellElement}
                      tableElement={tableElement}
                      anchorElement={anchorElem}
                      dispatchAction={handleDispatchAction}
                    />
                  </>
                )}
              </Fragment>
            );
          },
        )}
      </>
    );
  },
);
TableHoverActionsContainer.displayName = 'TableHoverActionsContainer';

function useTableSelection() {
  const [editor] = useLexicalComposerContext();
  const [selection, setSelection] = useState<
    | {
        table: TableNode;
        tableElement: HTMLElement;
        cells: TableCellNode[];
      }
    | undefined
  >(undefined);

  const updateSelectedTableCell = useCallback(() => {
    const selectedCells = getTableCellNodesFromSelection(editor);
    const table =
      selectedCells.length > 0
        ? $getTableNodeFromLexicalNodeOrThrow(selectedCells[0])
        : undefined;
    const tableElement = table
      ? editor.getElementByKey(table.getKey())
      : undefined;
    if (table && tableElement && selectedCells.length > 0) {
      setSelection({
        table: table,
        tableElement,
        cells: selectedCells,
      });
    } else {
      setSelection(undefined);
    }
  }, [editor]);

  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.getEditorState().read(updateSelectedTableCell);
    });
  }, [editor, updateSelectedTableCell]);

  return selection;
}

function getTableCellNodesFromSelection(editor: LexicalEditor) {
  const selection = $getSelection();
  const nativeSelection = window.getSelection();

  if (selection == null) {
    return [];
  }
  const rootElement = editor.getRootElement();
  if ($isTableSelection(selection)) {
    return selection.getNodes().filter($isTableCellNode);
  }
  if (
    $isRangeSelection(selection) &&
    rootElement !== null &&
    nativeSelection !== null &&
    rootElement.contains(nativeSelection.anchorNode)
  ) {
    const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(
      selection.anchor.getNode(),
    );

    if (tableCellNodeFromSelection == null) {
      return [];
    }

    const tableCellParentNodeDOM = editor.getElementByKey(
      tableCellNodeFromSelection.getKey(),
    );

    if (tableCellParentNodeDOM == null) {
      return [];
    }

    return [tableCellNodeFromSelection];
  }
  return [];
}

function $isCellInFirstRow(node: TableCellNode) {
  const row = node.getParent();
  if (row && row instanceof TableRowNode) {
    return row.getPreviousSibling() === null;
  }
  return false;
}

function $isCellInFirstColumn(node: TableCellNode) {
  return node.getPreviousSibling() === null;
}
