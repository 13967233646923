import {
  ExtensionProperty,
  ReportPapEventFn,
} from '@mirage/analytics/AnalyticsProvider';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { DashSurfaceBuild } from '@mirage/analytics/events/enums/dash_surface_build';
import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { tagged } from '@mirage/service-logging';
import { getIsDfbUser } from '@mirage/shared/eligibility';
import { Observable, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DashSurface } from '../../analytics/events/enums/dash_surface';
import { PapClient, PapClientConfig } from './pap';
import { findInvalidUUIDFields } from './uuids';

const logger = tagged('service-product-logging');

export type LocalPAPEvent = PAPEvent & {
  time: number;
  eventId: string;
};

const MAX_LOG_LENGTH = 1000;
const PAP_EVENTS: LocalPAPEvent[] = [];

export type Service = ReturnType<typeof productLogging>;

export type ServiceConfig = {
  papClientConfig: PapClientConfig;
  // Surface for the event being logged (webapp/desktop)
  dashSurface: DashSurface;
  logging: boolean;
  papEnvironment?: 'development' | 'production';
  // When isDev is true the pap client has additional logging for debug purposes and dev hmac key is used for stack ids
  isDev: boolean;
  // Version of the application being logged (1.0.0)
  buildVersion: string;
  // Unique identifier for a user's application install (uuid)
  installId?: string;
  // Extra dash surface information (hornet/tesla)
  dashSurfaceBuild?: DashSurfaceBuild;
  // This is only used by the event logging view
  copyToClipboard(value: PAPEvent): void | undefined;
  reportValidationErrorsToSentry: boolean;
  // Allow users to supply a custom event reporter.
  // TODO: Once we remove Scout's amplitude client, we can delete this field.
  reportPapEventOverride?: ReportPapEventFn;
  // Optional getExtensionPropertiesForLog method
  getExtensionPropertiesForLog?: () => ExtensionProperty;
};

export default function productLogging(config: ServiceConfig) {
  let papClient: PapClient | undefined;
  const localEvents$ = new Subject<LocalPAPEvent[]>();
  const notifyLocalEventsChanged = () => localEvents$.next(PAP_EVENTS);

  async function reportPapEvent(
    event: PAPEvent,
    flush?: boolean,
  ): Promise<void> {
    const invalidUUIDFields = findInvalidUUIDFields(event);
    if (invalidUUIDFields.length) {
      logger.warn(
        'invalid uuid fields found for pap event -- dropping!',
        invalidUUIDFields,
        event,
      );
      return;
    }

    const client = config.reportPapEventOverride
      ? { reportPapEvent: config.reportPapEventOverride }
      : getPapClient();

    const defaultProperties = {
      buildVersion: config.buildVersion,
      dashSurface: config.dashSurface,
      dashSurfaceBuild: config.dashSurfaceBuild,
      installId: config.installId,
      // set startTimeMs before isDfbUser fetch delays the timestamp
      startTimeMs: event.properties?.startTimeMs ?? Date.now(),
      isDfbUserV2: await getIsDfbUser(),
      ...(config.getExtensionPropertiesForLog
        ? config.getExtensionPropertiesForLog()
        : {}),
    };

    const combinedProperties = {
      ...defaultProperties,
      ...event.properties,
    };

    const flushOverride =
      // during playwright tests we want to always flush immediately to avoid waiting around for a batch to send
      (globalThis as unknown as Record<string, string>).PLAYWRIGHT_TEST_MODE ===
      '1'
        ? true
        : flush;

    const newEvent = await client.reportPapEvent(
      { ...event, properties: combinedProperties },
      flushOverride,
    );

    if (config.logging && newEvent) {
      logLocalEvent(newEvent);
      notifyLocalEventsChanged();
    }
  }

  async function batchReportPapEvents(events: PAPEvent[], flush?: boolean) {
    for (const event of events) {
      await reportPapEvent(event, flush);
    }
  }

  function batchLogLocalEvents(events: PAPEvent[]) {
    for (const event of events) {
      logLocalEvent(event);
    }
    notifyLocalEventsChanged();
  }

  function getPapClient() {
    if (!papClient) {
      const { papClientConfig, dashSurface } = config;
      const papEnvironment = config.papEnvironment ?? 'development';
      const logging = config.logging === undefined ? true : config.logging;

      papClient = new PapClient(
        papClientConfig,
        dashSurface,
        papEnvironment,
        logging,
        config.isDev,
        config.reportValidationErrorsToSentry,
      );
    }
    return papClient;
  }

  function logLocalEvent(event: PAPEvent) {
    const localPapEvent: LocalPAPEvent = {
      time: Date.now(),
      eventId: uuidv4(),
      ...event,
    };
    PAP_EVENTS.unshift(localPapEvent);
    if (PAP_EVENTS.length > MAX_LOG_LENGTH) {
      PAP_EVENTS.pop();
    }
  }

  function getLocalEvents() {
    return PAP_EVENTS;
  }

  function localEventsObservable(): Observable<LocalPAPEvent[]> {
    return localEvents$.asObservable();
  }

  function clearLocalEventLog() {
    PAP_EVENTS.splice(0, PAP_EVENTS.length);
    notifyLocalEventsChanged();
  }

  function copyToClipboard(event: PAPEvent) {
    if (!config.copyToClipboard) return;
    config.copyToClipboard(event);
  }

  return services.provide(
    ServiceId.PRODUCT_LOGGING,
    {
      reportPapEvent,
      batchReportPapEvents,
      batchLogLocalEvents,
      getLocalEvents,
      localEventsObservable,
      clearLocalEventLog,
      copyToClipboard,
    },
    [],
  );
}
