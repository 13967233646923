import { Button } from '@dropbox/dig-components/buttons';
import { FormLabel, FormRow } from '@dropbox/dig-components/form_row';
import { TextArea, TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { MAGIC_FLAG_SETTINGS } from './constants';
import { magicFlagSettings } from './magicFlagSettings';
import styles from './MagicFlagSettings.module.css';

export default function MagicFlagSettings() {
  const [magicFlags, setMagicFlags] = useAtom(magicFlagSettings);
  const [magicFlagKey, setMagicFlagKey] = useState('');
  const [magicFlagValue, setMagicFlagValue] = useState('');
  const [magicFlagConfigurationText, setMagicFlagConfigurationText] =
    useState('');

  const addMagicFlag = () => {
    setMagicFlags({
      ...magicFlags,
      [magicFlagKey]: magicFlagValue,
    });
    setMagicFlagKey('');
    setMagicFlagValue('');
  };

  const removeFlag = (flag: string) => {
    setMagicFlags((prev) => {
      const next = { ...prev };
      delete next[flag];
      return next;
    });
  };

  const generateAndCopyURL = async () => {
    const params = new URLSearchParams();
    params.set(MAGIC_FLAG_SETTINGS.QUERY_PARAM_KEY, JSON.stringify(magicFlags));
    await navigator.clipboard.writeText(
      `${window.location.origin}?${params.toString()}`,
    );
  };

  const copyConfiguration = async () => {
    await navigator.clipboard.writeText(JSON.stringify(magicFlags));
  };

  const pasteMagicFlagConfiguration = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setMagicFlagConfigurationText(e.target.value);
    const parsed = JSON.parse(e.target.value);
    setMagicFlags(parsed);
    setTimeout(() => {
      setMagicFlagConfigurationText('');
    }, 250);
  };

  return (
    <div>
      <div className={styles.magicFlagActions}>
        <Button variant="primary" onClick={generateAndCopyURL}>
          Generate Configuration URL
        </Button>
        <Button variant="primary" onClick={copyConfiguration}>
          Copy Configuration
        </Button>
      </div>
      {Object.keys(magicFlags).length === 0 ? (
        <Text tagName="p">
          <em>No magic flags added</em>
        </Text>
      ) : (
        <div className={styles.magicFlagTable}>
          <Text isBold color="subtle">
            Flag name
          </Text>
          <Text isBold color="subtle">
            Value
          </Text>
          <Text>&nbsp;</Text>
          {Object.entries(magicFlags).map(([k, v]) => {
            return (
              <>
                <Text>{k}</Text>
                <Text>{v}</Text>
                <Button variant="primary" onClick={() => removeFlag(k)}>
                  Remove
                </Button>
              </>
            );
          })}
        </div>
      )}
      <FormRow split>
        <FormRow>
          <FormLabel>Magic Flag</FormLabel>
          <TextInput
            value={magicFlagKey}
            onChange={(e) => setMagicFlagKey(e.target.value)}
          ></TextInput>
        </FormRow>
        <FormRow>
          <FormLabel>Value</FormLabel>
          <TextInput
            value={magicFlagValue}
            onChange={(e) => setMagicFlagValue(e.target.value)}
          ></TextInput>
        </FormRow>
        <FormRow>
          <FormLabel>&nbsp;</FormLabel>
          <Button variant="primary" onClick={addMagicFlag}>
            Add Flag
          </Button>
        </FormRow>
      </FormRow>
      <TextArea
        placeholder="Paste magic flag configuration..."
        value={magicFlagConfigurationText}
        onChange={pasteMagicFlagConfiguration}
      />
    </div>
  );
}
