import { Metadata } from '@dropbox/dash-component-library';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { PinFill } from '@dropbox/dig-icons/dist/mjs/assets';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { DashStackIcon } from '@mirage/mosaics/DCLWrappers/DashStackIcon';
import { TileCard } from '@mirage/mosaics/TileCard';
import { toggleStackIsPinned } from '@mirage/service-stacks';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import i18n from '@mirage/translations';
import { WorkingSetCardData } from '@mirage/working-set/types';
import classnames from 'classnames';
import { CSSProperties } from 'react';
import styles from '../AllStacksV2.module.css';

const actionSurfaceComponent = 'stacks';
const featureLine = 'stacks';

export const PinnedStackCard = ({
  stackData,
  hidden,
  style,
  hideSelectorClass,
}: {
  stackData: WorkingSetCardData;
  hidden?: boolean;
  style?: CSSProperties;
  hideSelectorClass?: boolean;
}) => {
  const hasMultipleMembers = stackData.memberCount > 1;

  return (
    <TileCard
      // Note: We need to conditionally apply this classname based on whether we're using the draggable pinned stack card or not
      // since this classname needs to be applied to the outer most boundary of our card in order for useDynamicExpandableContent
      // hook to properly calculate the number of visible cards
      className={classnames({
        [styles.pinnedCard]: !hideSelectorClass,
      })}
      hidden={hidden}
      interactiveAccessoryClassName={styles.interactiveAccessory}
      onClick={stackData.onClickCard}
      visibleUrl={`/stacks/${stackData.id}`}
      titleLinesBeforeTruncate={2}
      style={style}
      icon={
        <DashStackIcon
          size="medium"
          colorIndex={stackData.colorIndex}
          emoji={stackData.emoji}
        />
      }
      title={stackData.title}
      withAccessory={
        <IconButtonWithTooltip
          tooltipProps={{
            title: i18n.t('unpin_stack'),
          }}
          variant="borderless"
          onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            await toggleStackIsPinned(stackData.namespaceId);
          }}
          data-testid="PinStack"
          aria-label={i18n.t('unpin')}
          data-uxa-log={createUxaElementId('pin_button', {
            actionSurfaceComponent,
            featureLine,
          })}
          data-uxa-entity-id={stackData.namespaceId}
          size="medium"
        >
          <Text color="subtle" className={styles.pinButtonIcon}>
            <UIIcon src={PinFill} />
          </Text>
        </IconButtonWithTooltip>
      }
      withInteractiveAccessory
      metadata={
        <Metadata
          size="medium"
          withDividers="blank"
          className={classnames(
            styles.pinnedCardMetadata,
            !hasMultipleMembers && styles.accessIcon,
          )}
        >
          {hasMultipleMembers && !stackData.isCompanyPublishedStack ? (
            <Metadata.Item>
              {stackData.getMembersComponent('small')}
            </Metadata.Item>
          ) : (
            <>
              <Metadata.Item>
                <UIIcon src={stackData.accessLevelIcon} />
              </Metadata.Item>
              <Metadata.Item>
                <Metadata.Label>
                  <Text color="subtle">{stackData.accessLevel}</Text>
                </Metadata.Label>
              </Metadata.Item>
            </>
          )}
        </Metadata>
      }
    />
  );
};
