import { useQueryParams } from '@mirage/search/hooks/useQueryParams';
import { useDevFeatures } from '@mirage/service-auth/useDevFeatures';
import { useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { MAGIC_FLAG_SETTINGS } from './constants';
import { magicFlagSettings } from './magicFlagSettings';

interface MagicFlagItem {
  key: string;
  value: string;
}

export const useMagicFlags = () => {
  const enableDevFeatures = useDevFeatures();
  const queryParams = useQueryParams();
  const [magicFlags, setMagicFlags] = useAtom(magicFlagSettings);

  const magicFlagQueryParam = queryParams.get(
    MAGIC_FLAG_SETTINGS.QUERY_PARAM_KEY,
  );

  const magicFlagsArray: MagicFlagItem[] = useMemo(() => {
    return Object.entries(magicFlags).map(([key, value]) => ({
      key,
      value,
    }));
  }, [JSON.stringify(magicFlags)]);

  const magicFlagsString: string = useMemo(() => {
    return JSON.stringify(magicFlags);
  }, [JSON.stringify(magicFlags)]);

  useEffect(() => {
    if (enableDevFeatures && magicFlagQueryParam) {
      try {
        const magicFlagJSON = JSON.parse(magicFlagQueryParam);
        setMagicFlags(magicFlagJSON);
      } catch (e) {
        setMagicFlags({});
      }
    }
  }, [magicFlagQueryParam, enableDevFeatures]);

  return {
    map: magicFlags,
    list: magicFlagsArray,
    string: magicFlagsString,
  };
};
