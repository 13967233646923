import { stacks } from '@dropbox/api-v2-client';
import { IconButton, IconButtonSize } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { UIIcon, UIIconSize } from '@dropbox/dig-icons';
import { ChevronDownLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import React from 'react';

interface AddToSectionMenuProps {
  disabled?: boolean;
  // Only allow sizes that can be applied to both IconButton and UIIcon
  iconSize?: Extract<IconButtonSize, UIIconSize>;
  onSelectSection: (sectionId: string) => void;
  sections: stacks.Section[];
}

export const AddToSectionMenu: React.FC<AddToSectionMenuProps> = ({
  disabled,
  iconSize = 'small',
  onSelectSection,
  sections,
}) => {
  return (
    <Menu.Wrapper
      onClick={(e) => {
        // This menu may be nested inside of a larger clickable element (e.g. LinkSuggestion)
        // Keeping the events contained within the Menu ensures clicks don't trigger parent listeners
        // without impacting any menu functionality (e.g. click outside, trigger, select)
        e.stopPropagation();
        e.preventDefault();
      }}
      onKeyDown={(e) => {
        // Same comment as above
        e.stopPropagation();
        e.preventDefault();
      }}
      onSelection={onSelectSection}
    >
      {({ getContentProps, getTriggerProps, triggerRef }) => (
        <>
          <DigTooltip
            title={i18n.t('stacks_add_to_section')}
            triggerRef={triggerRef}
          >
            <IconButton
              {...getTriggerProps()}
              aria-label={i18n.t('stacks_add_to_section')}
              data-testid="Stacks-addtoSectionBtn"
              disabled={disabled}
              size={iconSize}
              variant="borderless"
            >
              <UIIcon size={iconSize} src={ChevronDownLine} />
            </IconButton>
          </DigTooltip>
          <Menu.Content
            {...getContentProps()}
            data-testid="Stacks-addtoSectionMenu"
          >
            <Menu.Segment withLabel={i18n.t('stacks_add_to_section')}>
              {sections.map((section) => (
                <Menu.ActionItem key={section.id} value={section.id}>
                  {section.title}
                </Menu.ActionItem>
              ))}
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
